/* eslint-disable jsx-a11y/no-static-element-interactions*/
/*
  TODO:  There is a linting error when providing interaction props to (div, th, td) elements,
         We don't want to replace these elements to (a, button), as some are styled for the specific element.
         this is supported with jsx-a11y version 6.0.2 when we specify a role to an element
         https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/no-static-element-interactions.md
         Remove disable line above after updating @clicktheraeutics-eslint-config-ct-base library to support jsx-a11y>6.02
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import moment from 'moment';
import { List } from 'immutable';

import { dataDispatch } from '../../../lib/react-annotations';
import { CoachActions } from '../../../actions';

import classnames from 'classnames';
import '../../../styles/3ps/bootstrap.min.css';
import styles from '../../../styles/coach.scss';

import { Columns, ColumnOrder, configsByColumnName } from './user-list-ui-helper';
import { DefaultFilter, FilterOrder, configsByFilterName } from './user-list-ui-helper';
import { DropdownDataSource } from './user-list-ui-helper';

@connect( (state) => {
  const { userData } = state;
  return {
    token: userData.get('sessionToken'),
    user: userData.get('user'),
    coachUserList: state.coachUserList
  };
})
@dataDispatch({
  coachUserList: {
    childProp: 'users',
    loader: 'fetchCoachUserList'
  }
})

export default class CoachUserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortKey: Columns.ID,
      sortAsc: false,
      filterKey: DefaultFilter,
      filterValue: null,
      appliedFilters: List()
    };
  }

  render() {
    const { coachUserList } = this.props;
    const loading = coachUserList.get('loading');
    if ( loading || loading === null ) {
      return (
        <div className={styles.container}>
          Coach dashboard user LIST page.
        </div>
      );
    }

    const users = coachUserList.get('users');

    // sorting users by specified column
    const { sortKey, sortAsc } = this.state;
    const config = configsByColumnName.get(sortKey);
    const sortedUsers = config.sortingMethod(users, sortAsc);

    const { appliedFilters } = this.state;
    const filteredSortedUsers = sortedUsers.filter((user) => {
      if (!appliedFilters.isEmpty()) {
        const notInclude = appliedFilters.find((filter) => {
          const filterConfig = configsByFilterName.get(filter.key);
          const excludeVal = filter.val.startsWith('!');
          const filterVal = excludeVal ? filter.val.substr(1) : filter.val;
          return filterConfig.filterMethod(user, filterVal.toLowerCase()) === excludeVal;
        });
        return !notInclude;
      }
      return true;
    });

    const scrollBoxHeight = (window.innerHeight - 40) + 'px';
    return (
      <div>
        <nav className="navbar navbar-inverse"
             style={{ margin: 0 }}>
          <ul className="nav navbar-nav">
            <li className={classnames('nav-item', 'active', styles.activeNav)}>
              <Link to={'/user'}
                    className="nav-link">
                User List
              </Link>
            </li>
            <li className="nav-item">
              <Link to={'/group/messages'}
                    className="nav-link">
                Group Messages
              </Link>
            </li>
          </ul>
        </nav>
        <div className={styles.filterDiv}>
          <form className={styles.filterOptions} >
            <div className={styles.filterTitles}>
              <div className={styles.filterTitle}>
                <strong>
                  Filter
                </strong>
              </div>
              <div className={styles.appliedFilterTitle}>
                <strong>
                  Applied Filters
                </strong>
              </div>
            </div>
            <div className={styles.appliedFilters}>
              { appliedFilters.map(this.renderAppliedFilter.bind(this)) }
            </div>
            <table className={styles.filterTable}>
              <tbody className={styles.filterTableBody}>
                { this.renderFilterRow() }
              </tbody>
            </table>
          </form>
        </div>
        <div className={styles.users_list}
             style={{ height: scrollBoxHeight, overflowY: 'scroll' }}>
          <table className={classnames('table', styles.users_table, 'table-hover')}>
            <thead>
              <tr>
                { ColumnOrder.map(this.renderColumnHeader.bind(this))}
              </tr>
            </thead>
            <tbody>
              { filteredSortedUsers.map(this.renderCoachedUser.bind(this)) }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderColumnHeader(columnType) {
    const title = configsByColumnName.get(columnType).title;
    return (
      <th key={columnType}
          onClick={this.onColumnHeadClick.bind(this, columnType)}
          role="button"
          tabIndex="0">
        { title }
      </th>
    );
  }

  renderCoachedUser(user, idx) {
    const jsUser = user.toJS();
    let location = `${jsUser.state_or_province || ''}, ${jsUser.country || ''}`.replace('United States of America', 'USA').trim();
    if (location === ',') location = '';

    const orderStatus = jsUser.order_status ? jsUser.order_status : '';
    const orderDate = jsUser.order_date ? moment(jsUser.order_date).format('YYYY-MM-DD') : '';
    const orderDisplay = orderStatus + ' ' + orderDate;

    const days = jsUser.start_date ? moment(jsUser.start_date).fromNow() : 0;
    return (
      <tr key={idx}
          onClick={this.onUserClick.bind(this, jsUser)}
          role="button"
          tabIndex="0">
        <td>{jsUser.id}</td>
        <td>{jsUser.first_name} {jsUser.last_name}</td>
        <td>{location}</td>
        <td>{jsUser.phone_number}</td>
        <td>{days || ''}</td>
        <td>{jsUser.client_name}</td>
        <td>{jsUser.sms_disabled ? 'Stop' : null}</td>
        <td>{orderDisplay}</td>
      </tr>
    );
  }

  renderFilterOption(filter) {
    const { filterKey } = this.state;
    const filterConfig = configsByFilterName.get(filter);
    return (
      <tr className={classnames('radio', styles.filter_input)}>
        <td>
          <label htmlFor="FilterOption">
            <input type="checkbox"
                   name="filter"
                   value={filter || ''}
                   onChange={this.handleFilterOptionChange.bind(this, filter)}
                   checked={filter === filterKey} />
            { filterConfig.title }
          </label>
        </td>
        <td>
          { filter === DefaultFilter ? null : this.renderFilterInput(filter) }
        </td>
      </tr>
    );
  }

  renderAppliedFilter(filter, index) {
    const filterText = configsByFilterName.get(filter.key).title + ' ' + filter.val;
    return (
      <div className={styles.appliedFilter}
           key={index}>
        <span className={styles.appliedFilterText}>{filterText}</span>
        <button type="button"
                className={classnames('close', styles.appliedFilterClose)}
                aria-label="Close"
                onClick={this.removeAppliedFilter.bind(this, filter)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }

  renderFilterRow() {
    const { filterKey, filterValue, appliedFilters } = this.state;
    return (
      <tr className={styles.filterInput}>
        <td className={styles.filterType}>
          <select type="form-control"
            className={classnames('filterType', styles.filter_dropdown)}
            onChange={this.handleFilterTypeChange.bind(this)}>
            { FilterOrder.map((filter, idx) => (
              <option value={filter}
                      key={idx}>
                { configsByFilterName.get(filter).title }
              </option>
            )) }
            );
          </select>
        </td>
        <td className={styles.filterValue}>
          { filterKey === DefaultFilter ? null : this.renderFilterInput(filterKey) }
        </td>
        <td className={styles.filterAdd}>
          <button className="btn btn-primary"
                  onClick={this.addFilter.bind(this)}
                  disabled={filterKey === DefaultFilter || !filterValue}>
                  Add Filter
          </button>
        </td>
        <td className={styles.filterClear}>
          <button className="btn btn-secondary"
                  onClick={this.clearFilters.bind(this)}
                  disabled={appliedFilters.isEmpty()}>
                  Clear Filters
          </button>
        </td>
      </tr>
    );
  }

  renderFilterInput(filter) {
    return DropdownDataSource.has(filter) ? this.renderFilterDropdownInput(filter) : this.renderFilterTextInput(filter);
  }

  renderFilterTextInput(filter) {
    const { filterKey, filterValue } = this.state;
    const inputValue = filterKey === filter ? filterValue : '';
    return (
      <input type="text"
              className={classnames('filterValue', styles.filter_text_input)}
              onChange={this.handleFilterValueChange.bind(this, filter)}
              name={filter}
              value={inputValue || ''}/>
    );
  }

  renderFilterDropdownInput(filter) {
    const dropdownOptions = DropdownDataSource.get(filter);
    if (!dropdownOptions) {
      return null;
    }
    const { filterKey, filterValue } = this.state;
    const defaultDropdown = dropdownOptions.DEFAULT;
    const dropdownValue = filterKey === filter ? filterValue : defaultDropdown;
    return (
      <select type="form-control"
        className={classnames('filterValue', styles.filter_dropdown)}
        onChange={this.handleFilterValueChange.bind(this, filter)}>
        { dropdownOptions.map((status) => (
          <option selected={status === dropdownValue}>
            { status }
          </option>
        )) }
        );
      </select>
    );
  }

  onUserClick(user) {
    this.props.history.push('/user/' + user.id);
  }

  onColumnHeadClick(columnHead) {
    const { sortKey, sortAsc } = this.state;
    this.setState({
      sortKey: columnHead === sortKey ? sortKey : columnHead,
      sortAsc: columnHead === sortKey ? !sortAsc : true
    });
  }

  handleFilterTypeChange(filter) {
    this.setState({
      filterKey: filter.target.value,
      filterValue: null
    });
  }

  handleFilterValueChange(filter, event) {
    this.setState({
      filterValue: event.target.value
    });
  }

  addFilter() {
    const { filterKey, filterValue, appliedFilters } = this.state;
    const newFilter = { key: filterKey, val: filterValue };
    const newFilters = appliedFilters.concat(newFilter);
    this.setState({
      filterKey: DefaultFilter,
      filterValue: null,
      appliedFilters: newFilters
    });
  }

  clearFilters() {
    this.setState({
      filterKey: DefaultFilter,
      filterValue: null,
      appliedFilters: List()
    });
  }

  removeAppliedFilter(filter) {
    const { appliedFilters } = this.state;
    const removalIndex = appliedFilters.indexOf(filter);
    const newFilters = appliedFilters.delete(removalIndex);
    this.setState({
      appliedFilters: newFilters
    });
  }

  fetchCoachUserList() {
    return CoachActions.fetchCoachUserList(this.props.token);
  }
}
