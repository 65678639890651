import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import classnames from 'classnames';
import styles from '../../styles/coach-header.scss';



@connect( (state) => {
  const { userData } = state;
  return {
    token: userData.get('sessionToken'),
    user: userData.get('user')
  };
})
export default class Header extends Component {
  render() {
    const { user } = this.props;

    return (
      <div>
        <div className={classnames('navbar', 'navbar-default', 'navbar-fixed-top', styles.body, this.props.className)}>
          <Link to="/">
            <img src="images/click_magellan.jpg"
                 className={styles.logo}
                 role="presentation" />
          </Link>
          <div className={styles.coach}>
            <div className={styles.coachBody}>
              <span className={styles.name}>{ user.get('firstName') } { user.get('lastName') }</span><br />
              <span className={styles.email}>{ user.get('email') }</span>
            </div>
          </div>
        </div>
        <div className={styles.body}>&nbsp;</div>
      </div>
    );
  }
}
