import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import moment from 'moment';

import { CoachActions } from '../../actions';
import { dataDispatch } from '../../lib/react-annotations';

import classnames from 'classnames';
import '../../styles/3ps/bootstrap.min.css';
import styles from '../../styles/coach-user.scss';


@connect( (state) => {
  const { userData } = state;
  return {
    token: userData.get('sessionToken'),
    user: userData.get('user'),
    coachedUser: state.coachedUser,
    coachedUserMessages: state.coachedUserMessages
  };
})
@dataDispatch({
  coachedUserMessages: {
    childProp: 'messages',
    loader: 'loadUserMessages'
  }
})
export default class CoachUserMessages extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showFromUser: true,
      showFromCoach: true,
      showFromSystem: true,
      showPushes: true,
      showGroupsOnly: false,
      sendMessageValue: null
    };
  }

  // lifecycle method
  componentDidMount() {
    this.scrollToBottomOfMessages();

    this.handleResize = () => {
      this.refs.message_scroll_box.style.height = (window.innerHeight - 330) + 'px';
    };
    window.addEventListener('resize', this.handleResize);
  }

  // lifecycle method
  componentDidUpdate() {
    this.scrollToBottomOfMessages();
  }

  // lifecycle method
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }


  render() {
    // TODO: when the server side finishes moving from lame enums to constants, this can go back
    const messageTypes = [ 'outbound', 'inbound', 'push' ];

    const coachedUserMessages = this.props.coachedUserMessages;
    if (coachedUserMessages.get('loading')) {
      return (
        <h3>Loading User Messages...</h3>
      );
    }

    const scrollBoxHeight = (window.innerHeight - 330) + 'px';
    const coachedUser = this.props.coachedUser.get('user').toJS();

    const baseMessages = coachedUserMessages.get('messages') ? coachedUserMessages.get('messages').toJS() : [];

    const countStats = { inbound: 0, outbound: 0, push: 0 };

    baseMessages.forEach((message) => {
      countStats[message.message_type]++;
    });

    const baseMessagesWithNamedMessageTypes = baseMessages.map((message) => {
      const messageTypeEnumValue = message.message_type;
      return {
        ...message,
        message_type: messageTypes[messageTypeEnumValue]
      };
    });

    const filteredMessages = baseMessagesWithNamedMessageTypes.filter((message) => {
      if (this.state.showGroupsOnly && message.group_id) return true;

      switch (message.message_type) {
        case 'inbound': return this.state.showFromUser;
        case 'outbound': return message.sender_id ? this.state.showFromCoach : this.state.showFromSystem;
        case 'push': return this.state.showPushes;
        default: return true;
      }
    });

    return (
      <div className={classnames(styles.detailsBox, 'col-sm-10')}>
        {/* TODO: need a sexy subnav component for user pages */}
        <nav className="navbar navbar-inverse"
             style={{ margin: 0 }}>
          <ul className="nav navbar-nav">
            <li className="nav-item">
              <Link to={`/user/${coachedUser.id}`}
                    className="nav-link">
                Profile
              </Link>
            </li>
            <li className={classnames('nav-item', 'active', styles.activeNav)}>
              <Link to={`/user/${coachedUser.id}/messages`}
                    className="nav-link">
                Messages
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`/user/${coachedUser.id}/clickstream`}
                    className="nav-link">
                Clickstream
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`/user/${coachedUser.id}/notes`}
                    className="nav-link">
                Notes
              </Link>
            </li>
          </ul>
        </nav>

        <div>
          <div className={classnames('col-lg-9', styles.messagesLeft)}>
            <h3>User Messages</h3>

            {/* MESSAGE LIST */}
            <div ref="message_scroll_box"
                 style={{ height: scrollBoxHeight, overflowY: 'scroll' }}>
              { filteredMessages.map(this.renderMessage.bind(this)) }
            </div>

            {/* SEND TEXT PANEL */}
            <div className={classnames('row', styles.sendMessage)}>
              { this.renderSendMessage() }
            </div>
            <div>Text message will be sent to: { coachedUser.phone_number }</div>
          </div>

          {/* RIGHT HAND INFO/SORTS PANEL */}
          <div className={classnames( 'col-lg-3', styles.motivesRight )}>
            <div className={styles.viewOptions}>

              <strong>{baseMessagesWithNamedMessageTypes.length} Messages</strong><br />
              {countStats.outbound} outbound<br />
              {countStats.inbound} inbound<br />
              {countStats.push} push notifications<br />

            </div>
            <hr />

            <div className={styles.viewOptions}>
              <h4><strong>View Options</strong></h4>
              <div className="checkbox">
                <label htmlFor="InboundFromUserCheck">
                  <input type="checkbox"
                         onChange={this.handleViewOptionChange.bind(this, 'showFromUser')}
                        checked={this.state.showFromUser} />
                  Inbound From User
                </label>
              </div>
              <div className="checkbox">
                <label htmlFor="OutboundFromCoachCheck">
                  <input type="checkbox"
                         onChange={this.handleViewOptionChange.bind(this, 'showFromCoach')}
                         checked={this.state.showFromCoach} />
                    Outbound From Coaches
                </label>
              </div>
              <div className="checkbox">
                <label htmlFor="OutboundFromSystemCheck">
                  <input type="checkbox"
                         onChange={this.handleViewOptionChange.bind(this, 'showFromSystem')}
                         checked={this.state.showFromSystem} />
                  Outbound From System
                </label>
              </div>
              <div className="checkbox">
                <label htmlFor="OutboundPushNotificationCheck">
                  <input type="checkbox"
                         onChange={this.handleViewOptionChange.bind(this, 'showPushes')}
                         checked={this.state.showPushes} />
                  Outbound Push Notifications
                </label>
              </div>
              <hr />
              <div className="checkbox">
                <label htmlFor="ShowQuitTeamsOnly">
                  <input type="checkbox"
                         onChange={this.handleViewOptionChange.bind(this, 'showGroupsOnly')}
                         checked={this.state.showGroupsOnly} />
                  Show Quit Teams Only
                </label>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }


  renderMessage(message, key) {
    if (!message) return null;

    const infoLine = [];

    infoLine.push(moment(message.created_at || new Date()).format('YYYY-MM-DD hh:mm a'));
    infoLine.push(' : ');

    if (message.group_id) {
      infoLine.push(
        <span>&nbsp;( group: {message.group_id} )&nbsp;</span>
      );
    }

    infoLine.push(
      <span className={styles.sender}> { message.message_type === 'inbound'
        ? this.props.coachedUser.get('user').get('first_name')
        : message.sender_name
      }</span>
    );

    if (message.message_type === 'inbound') {
      // flip it for inbound messages
      infoLine.reverse();
    }

    if (message.message_type === 'push') {
      infoLine.push(
        <span>( push notification )</span>
      );
    }

    const bubbleStyle = (message.message_type === 'outbound' && message.sender_id ? 'coach' : message.message_type) + '-bubble';

    return (
      <div key={'message-' + key}
           className={classnames(styles.message, styles[message.message_type])}>
        <div className="row">
          <div className={classnames(styles[bubbleStyle], (message.group_id ? styles['group-' + message.message_type + '-bubble'] : ''))}>
            { message.body }
          </div>
        </div>
        { this.renderMessageMedia.bind(this)(message) }
        <div className={styles.message_info}>
          { infoLine.map((info, idx) => <div key={idx}>{info}</div>) }
        </div>
      </div>
    );
  }

  renderSendMessage() {
    const placeholderText = 'Write text message...';
    const numRows = '3';

    const buttonText = 'Send Message';

    return (
      <div>
        <div className="col-md-10" >
          <fieldset className="form-group">
            <textarea type="text"
                      className="form-control"
                      placeholder={placeholderText}
                      value={this.state.sendMessageValue || ''}
                      rows={numRows}
                      onKeyPress={this.handleSendMsgKeyPress}
                      onChange={this.handleSendMsgChange.bind(this)} />
          </fieldset>
        </div>
        <button onClick={this.handleSendMsgSubmit.bind(this)}
                className="btn btn-primary col-md-2">
          { buttonText }
        </button>
      </div>
    );
  }

  renderMessageMedia(message) {
    /* eslint eqeqeq: 0 */
    if (!message || message.media_count == undefined || message.media_count < 1) {
      return null;
    }

    const urls = message.media.split(',');

    return (
      <div className={styles.message_info} >
        { urls.map((url) => (
          <a href={url}
             target="_blank"
             rel="noopener noreferrer">
             <img src={url}
                  role="presentation"/>
          </a> ))
        }
      </div>
    );
  }

  scrollToBottomOfMessages() {
    const scrollBox = this.refs.message_scroll_box;
    if (scrollBox) scrollBox.scrollTop = scrollBox.scrollHeight;
  }

  handleViewOptionChange(tag, event) {
    const checked = event.target.checked;

    // showGroupsOnly is special, to be selected in isolation
    if (tag === 'showGroupsOnly') {
      this.setState({
        showGroupsOnly: checked,
        showFromUser: !checked,
        showFromCoach: !checked,
        showFromSystem: !checked,
        showPushes: !checked
      });
    } else {
      this.setState({
        showGroupsOnly: false,
        [tag]: checked
      });
    }
  }

  handleSendMsgKeyPress = (event) => {
    if (event.keyCode === 0 && event.ctrlKey) {
      this.handleSendMsgSubmit(event);
    }
  }

  handleSendMsgChange(event) {
    this.setState({ sendMessageValue: event.target.value });
  }

  handleSendMsgSubmit(event) {
    event.preventDefault();
    const { sendMessageValue } = this.state;
    if (sendMessageValue) {
      const messageText = sendMessageValue || '';
      if (!messageText.length) return;

      const message = {
        user_id: this.props.params.userId,
        body: messageText,
        sender_id: this.props.user.get('id')
      };

      this.props.dispatch(CoachActions.sendMessageToUser(this.props.token, message.user_id, message));

      this.setState({ sendMessageValue: '' });
    }
  }

  loadUserMessages() {
    return CoachActions.fetchCoachedUserMessages(this.props.token, this.props.params.userId);
  }
}
