import React from 'react';
import { Component } from 'react';
import { Coach } from '../components';

import styles from '../styles/coach.scss';

export default class CoachPage extends Component {
  render() {
    return (
      <div style={{ overflow: 'hidden' }}>
        <Coach.Header />

        {this.props.children}

        <div className={styles.footer}>
          <p className="text-muted">Powered By Clickometrics™</p>
        </div>
      </div>
    );
  }
}
