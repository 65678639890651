import { Map } from 'immutable';
import fetch from 'isomorphic-fetch';
import config from './config';

const API_ROOT = config.apiRoot;

export class ApiError extends Error {
  constructor(message, status) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.status = status;
    Error.captureStackTrace(this, this.constructor.name);
  }
}

// system-level, un-retriable error
export class SystemError extends ApiError { }

// user error, retriable with different params
export class UserError extends ApiError {
  constructor(message, status, data) {
    super(message, status);
    this.data = data;
  }
}

export function login(email, password) {
  return post(null, url('sessions'), null, {
    user: {
      email_address: email,
      password
    },
    session_type: 'coach'
  }).then((res) => (
    Map({
      token: res.token,
      user: userFromServer(res.user)
    })
  ));
}

export function requestTemporaryPassword(email) {
  return post(null, url('password_resets'), null, {
    password_reset: {
      email_address: email
    }
  });
}

export function fetchCoachUsersList(token) {
  return get('users', url('coach/user'), token);
}

export function fetchCoachedUser(token, userId) {
  return get('user', url('coach/user/' + userId), token);
}

export function setClientToClickDump(token, coachedUserId) {
  return post('new_client', url('coach/set_user_to_click_dump_client'), token, { coached_user_id: coachedUserId });
}

export function fetchCoachedUserMessages(token, userId) {
  return get('messages', url('coach/messages/user/' + userId), token);
}

export function sendMessageToUser(token, userId, message) {
  return post(null, url('coach/messages/send'), token, message);
}

export function fetchGroupMessages(token, params) {
  return get('messages', url('coach/messages/group'), token, params);
}

export function moderateGroupMessages(token, moderations) {
  return post(null, url('coach/messages/group/moderate'), token, moderations);
}

export function sendMessageToGroup(token, message) {
  return post(null, url('coach/messages/group/send_message'), token, { message });
}

export function fetchCoachedUserNotes(token, userId) {
  return get('notes', url(`users/${userId}/notes`), token);
}

export function createNewUserNote(token, userId, note) {
  return post(null, url(`users/${userId}/notes`), token, note);
}

export function updateUserNote(token, userId, noteId, text) {
  return put(null, url(`users/${userId}/notes/${noteId}`), token, { note: text });
}

export function fetchJustSmokedEvents(token, userId) {
  return get('just_smoked_events', url(`users/${userId}/just_smoked_events`), token);
}

export function fetchDailyCheckins(token, userId) {
  return get('daily_checkins', url(`users/${userId}/daily_checkins`), token);
}

export function fetchWeeklyCheckins(token, userId) {
  return get('weekly_checkins', url(`users/${userId}/weekly_checkins`), token);
}

export function fetchCravings(token, userId) {
  return get('cravings', url(`users/${userId}/cravings`), token);
}

export function fetchCoachMessagesListWithDateRange(token, fromDate, toDate, messageType) {
  return post('messages', url('coach/messages/list'), token, { from_date: fromDate.utc().format(), to_date: toDate.utc().format(), message_type: messageType });
}

function userFromServer(serverUser) {
  if ( !serverUser ) {
    return null;
  }

  return Map({
    id: serverUser.id,
    profilePicUrl: serverUser.profile_photo && serverUser.profile_photo.url,
    firstName: serverUser.first_name,
    lastName: serverUser.last_name,
    email: serverUser.email_address
  });
}

function post(responseKey, postUrl, token, body) {
  return fetch(postUrl, {
    method: 'post',
    headers: getHeaders(token),
    body: JSON.stringify(body)
  }).then(handleResponse.bind(null, responseKey));
}

function put(responseKey, postUrl, token, body) {
  return fetch(postUrl, {
    method: 'POST',
    headers: getHeaders(token, 'PATCH'),
    body: JSON.stringify(body)
  }).then(handleResponse.bind(null, responseKey));
}

function get(responseKey, getUrl, token, params) {
  return fetch(getUrl + queryStringParams(params), {
    method: 'get',
    headers: getHeaders(token)
  }).then(handleResponse.bind(null, responseKey));
}

function getHeaders(token, methodOverride) {
  const headers = new Headers({
    'Content-Type': 'application/json'
  });
  if (methodOverride) {
    headers.set('x-http-method-override', methodOverride);
  }

  if ( token ) {
    headers.set('Authorization', `Token ${token}`);
  }

  return headers;
}

function url(path) {
  return `${API_ROOT}/${path}`;
}

function handleResponse(responseKey, response) {
  if ( response.ok ) {
    return response.text().then((text) => {
      const data = text ? JSON.parse(text) : null;
      return (responseKey && data) ? data[responseKey] : data;
    });
  }

  const status = response.status;
  if ( status >= 400 && status < 500 ) {
    return response.json().then(throwUserError, throwUserError);
  }

  throw new SystemError(response.statusText, status);

  function throwUserError(data) {
    throw new UserError(response.statusText, status, data);
  }
}

function queryStringParams(params) {
  if (!params) {
    return '';
  }

  const immutableMapParams = Map(params);
  return immutableMapParams.reduce( (rVal, value, key) => {
    const prefix = rVal.length === 0 ? '?' : '&';
    return prefix + rVal + key + '=' + encodeURIComponent(params[key]);
  }, '');
}
