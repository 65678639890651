import { ActionTypes } from '../constants';
import { Api } from '../lib';

export function sendMessageToGroup(token, message) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.COACH_REQUEST_SEND_GROUP_MESSAGE });

    Api.sendMessageToGroup(token, message).then(
      () => dispatch({
        type: ActionTypes.COACH_GROUP_MESSAGE_SENT,
        payload: message
      })
    ).catch(
      (err) => dispatch({
        type: ActionTypes.COACH_FAILED_SENDING_GROUP_MESSAGE,
        payload: { error: err }
      })
    );
  };
}

export function fetchGroupMessages(token, messageStatus) {
  return (dispatch) => {
    dispatch(_requestGroupMessages());
    Api.fetchGroupMessages(token, messageStatus).then(
      (groupMessages) => dispatch(_receivedGroupMessages(groupMessages))
    ).catch(
      (err) => dispatch(_failedGroupMessages(err))
    );
  };
}

function _requestGroupMessages() {
  return {
    type: ActionTypes.COACH_REQUEST_GROUP_MESSAGES
  };
}

function _receivedGroupMessages(messages) {
  return {
    type: ActionTypes.COACH_RECEIVED_GROUP_MESSAGES,
    payload: {
      messages
    }
  };
}

function _failedGroupMessages(error) {
  return {
    type: ActionTypes.COACH_FAILED_GROUP_MESSAGES,
    payload: { error }
  };
}

export function moderateGroupMessages(token, moderations) {
  return (dispatch) => {
    dispatch(_moderateMessages());

    Api.moderateGroupMessages(token, moderations).then(
      () => dispatch(_completedMessageModeration())
    ).catch(
      (err) => dispatch(_failedMessageModeration(err))
    );
  };
}

function _moderateMessages() {
  return {
    type: ActionTypes.COACH_MODERATE_GROUP_MESSAGES
  };
}

function _completedMessageModeration() {
  return {
    type: ActionTypes.COACH_COMPLETED_MESSAGE_MODERATION
  };
}

function _failedMessageModeration(error) {
  return {
    type: ActionTypes.COACH_FAILED_MESSAGE_MODERATION,
    payload: { error }
  };
}

export function updateFilterType(filterType) {
  return {
    type: ActionTypes.MESSAGE_FILTER_TYPE_CHANGE,
    payload: {
      filterType
    }
  };
}

export function fetchMessagesInDateRange(token, fromDate, toDate, messageType) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.MESSAGES_WITH_DATE_RANGE_REQUESTED,
      payload: { }
    });

    Api.fetchCoachMessagesListWithDateRange(token, fromDate, toDate, messageType).then( (messages) => {
      dispatch({
        type: ActionTypes.MESSAGES_WITH_DATE_RANGE_RECEIVED,
        payload: {
          messages
        }
      });
    }).catch((error) => {
      dispatch({
        type: ActionTypes.MESSAGES_WITH_DATE_RANGE_FAILED,
        payload: {
          error
        }
      });
    });
  };
}
