import * as NameUtils from './name-utils';
import propTypes from './prop-types';
import * as Api from './api';
import history from './history';
import * as NamedCoachRoutes from './named-coach-routes';
import logger from './logger';
import * as ReactAnnotations from './react-annotations';
import config from './config';
import * as cookies from './cookies';
import devTools from './dev-tools';

const NamedRoutes = NamedCoachRoutes;

export {
  Api,
  NameUtils,
  propTypes,
  history,
  NamedRoutes,
  logger,
  ReactAnnotations,
  config,
  cookies,
  devTools
};
