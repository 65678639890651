import { Map } from 'immutable';
import { logger } from '../lib';
import { ActionTypes } from '../constants';

const initialState = {
  loading: null,
  error: null,
  user: null
};

export default function coachedUser(state = Map(initialState), action) {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.COACH_REQUEST_USER:
      return state.merge({ ...initialState, loading: true });

    case ActionTypes.COACH_RECEIVED_USER:
      return state.merge({ ...initialState, user: payload.coachedUser, loading: false });

    case ActionTypes.COACH_FAILED_USER: {
      logger.error('Failed to get coachedUser', payload.error);
      return state.merge({ ...initialState, error: payload.error });
    }

    case ActionTypes.COACH_REQUEST_CLIENT_CHANGE: {
      return state.merge({
        loading: true
      });
    }
    case ActionTypes.COACH_CLIENT_CHANGE_SUCCESS: {
      const { clientId, clientName } = payload;
      return state.merge({
        loading: false
      }).update('user', (user) => (
        user.merge({
          client: {
            id: clientId,
            name: clientName
          }
        })
      ));
    }
    case ActionTypes.COACH_CLIENT_CHANGE_FAIL: {
      logger.error('Failed to reset client for coachedUser', payload.error);
      return state.merge({
        loading: false,
        error: payload.error
      });
    }
    default:
      return state;
  }
}
