import { combineReducers } from 'redux';
import { routeReducer } from 'redux-simple-router';

import user from './user';
import coachUserList from './coach-user-list';
import coachedUser from './coached-user';
import coachedUserMessages from './coached-user-messages';
import coachedUserNotes from './coached-user-notes';
import messages from './messages';
import * as CoachClickstream from './coach-clickstream';
import nav from './nav';

const rootReducer = combineReducers({
  nav,
  userData: user,
  coachUserList,
  coachedUser,
  coachedUserMessages,
  coachedUserNotes,
  clickstreamCravings: CoachClickstream.clickstreamCravings,
  clickstreamDailyCheckins: CoachClickstream.clickstreamDailyCheckins,
  clickstreamWeeklyCheckins: CoachClickstream.clickstreamWeeklyCheckins,
  clickstreamSmokedEvents: CoachClickstream.clickstreamSmokedEvents,
  messages,
  routing: routeReducer
});

export default rootReducer;
