exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".coach-clickstream__detailsBox___1Zi5g {\n  padding: 0;\n  margin: 0;\n  color: #555; }\n\n.coach-clickstream__contentBox___3geeI {\n  padding: 15px; }\n\n.coach-clickstream__activeNav___vqI-G {\n  color: #74eefd;\n  border-bottom: 3px solid #74eefd; }\n  .coach-clickstream__activeNav___vqI-G a {\n    color: #74eefd; }\n  .coach-clickstream__activeNav___vqI-G a:hover {\n    color: #74eefd; }\n  .coach-clickstream__activeNav___vqI-G a:visited {\n    color: #74eefd; }\n\n.coach-clickstream__streamTitles___2gm2z .coach-clickstream__track___2iVV_ {\n  font-size: 25px;\n  padding: 15px;\n  text-align: right;\n  border-right: 1px solid #eee;\n  background-color: #fafafa; }\n\n.coach-clickstream__streamData___18RWW {\n  overflow-x: scroll; }\n\n.coach-clickstream__track___2iVV_ {\n  height: 105px;\n  border-bottom: 1px solid #ddd; }\n\n.coach-clickstream__bulletOne___3DWiH {\n  background-color: #ffc149;\n  border-radius: 20px;\n  float: left;\n  height: 85px;\n  width: 30px;\n  margin: 10px 0 10px 0; }\n\n.coach-clickstream__bulletTwo___3G6Xh {\n  background-color: #68f289; }\n\n.coach-clickstream__bulletThree___qmXRR {\n  background-color: #f268f0; }\n\n.coach-clickstream__bulletFour___3Yhcz {\n  background-color: #fd4e4e; }\n", ""]);

// Exports
exports.locals = {
	"detailsBox": "coach-clickstream__detailsBox___1Zi5g",
	"contentBox": "coach-clickstream__contentBox___3geeI",
	"activeNav": "coach-clickstream__activeNav___vqI-G",
	"streamTitles": "coach-clickstream__streamTitles___2gm2z",
	"track": "coach-clickstream__track___2iVV_",
	"streamData": "coach-clickstream__streamData___18RWW",
	"bulletOne": "coach-clickstream__bulletOne___3DWiH",
	"bulletTwo": "coach-clickstream__bulletTwo___3G6Xh coach-clickstream__bulletOne___3DWiH",
	"bulletThree": "coach-clickstream__bulletThree___qmXRR coach-clickstream__bulletOne___3DWiH",
	"bulletFour": "coach-clickstream__bulletFour___3Yhcz coach-clickstream__bulletOne___3DWiH"
};