import { ActionTypes } from '../constants';
import { Api } from '../lib';

/** USER LIST */
export function fetchCoachUserList(token) {
  return (dispatch) => {
    dispatch(_requestUserList());

    Api.fetchCoachUsersList(token).then(
      (coachUserList) => dispatch(_receivedUserList(coachUserList))
    ).catch(
      (err) => dispatch(_failedUserList(err))
    );
  };
}

// note: the underscore is smallish notation for something
// that shouldn't be used outside of this file
function _requestUserList() {
  return {
    type: ActionTypes.COACH_REQUEST_USER_LIST
  };
}

function _receivedUserList(coachUserList) {
  return {
    type: ActionTypes.COACH_RECEIVED_USER_LIST,
    payload: {
      coachUserList
    }
  };
}

function _failedUserList(error) {
  return {
    type: ActionTypes.COACH_FAILED_USER_LIST,
    payload: { error }
  };
}

/** USER DETAILS */
export function fetchCoachedUser(token, coachedUserId) {
  return (dispatch) => {
    dispatch(_requestCoachedUser());

    Api.fetchCoachedUser(token, coachedUserId).then(
      (coachedUser) => dispatch(_receivedCoachedUser(coachedUser))
    ).catch(
      (err) => dispatch(_failedCoachedUser(err))
    );
  };
}

function _requestCoachedUser() {
  return {
    type: ActionTypes.COACH_REQUEST_USER
  };
}

function _receivedCoachedUser(coachedUser) {
  return {
    type: ActionTypes.COACH_RECEIVED_USER,
    payload: {
      coachedUser
    }
  };
}

function _failedCoachedUser(error) {
  return {
    type: ActionTypes.COACH_FAILED_USER,
    payload: { error }
  };
}

export function setUserToClickClient(coachedUserId, token) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.COACH_REQUEST_CLIENT_CHANGE,
      payload: {}
    });

    Api.setClientToClickDump(token, coachedUserId).then( (newClient) => {
      const newClientId = newClient.id;
      const newClientName = newClient.name;

      dispatch({
        type: ActionTypes.COACH_CLIENT_CHANGE_SUCCESS,
        payload: {
          clientId: newClientId,
          clientName: newClientName
        }
      });
      dispatch({
        type: ActionTypes.UPDATE_COACHED_USER_IN_LIST_FOR_NEW_CLIENT,
        payload: {
          userId: coachedUserId,
          clientId: newClientId,
          clientName: newClientName
        }
      });
    }).catch((error) => {
      dispatch({
        type: ActionTypes.COACH_CLIENT_CHANGE_FAIL,
        payload: {
          error
        }
      });
    });
  };
}


/** USER MESSAGES */
export function fetchCoachedUserMessages(token, userId) {
  return (dispatch) => {
    dispatch(_requestCoachedUserMessages());

    Api.fetchCoachedUserMessages(token, userId).then(
      (coachedUserMessages) => dispatch(_receivedCoachedUserMessages(coachedUserMessages))
    ).catch(
      (err) => dispatch(_failedCoachedUserMessages(err))
    );
  };
}

function _requestCoachedUserMessages() {
  return {
    type: ActionTypes.COACH_REQUEST_USER_MESSAGES
  };
}

function _receivedCoachedUserMessages(messages) {
  return {
    type: ActionTypes.COACH_RECEIVED_USER_MESSAGES,
    payload: {
      messages
    }
  };
}

function _failedCoachedUserMessages(error) {
  return {
    type: ActionTypes.COACH_FAILED_USER_MESSAGES,
    payload: { error }
  };
}

/** SEND MESSAGE TO USER */
export function sendMessageToUser(token, userId, message) {
  return (dispatch) => {
    dispatch(_requestSendMessageToUser());

    Api.sendMessageToUser(token, userId, message).then(
      (returnedMessage) => dispatch(_messageSentToUser(returnedMessage))
    ).catch(
      (err) => dispatch(_failedSendingMessage(err))
    );
  };
}

function _requestSendMessageToUser() {
  return {
    type: ActionTypes.COACH_REQUEST_SEND_MESSAGE
  };
}

function _messageSentToUser(message) {
  return {
    type: ActionTypes.COACH_MESSAGE_SENT,
    payload: message
  };
}

function _failedSendingMessage(error) {
  return {
    type: ActionTypes.COACH_FAILED_SENDING_MESSAGE,
    payload: { error }
  };
}


/** USER NOTES */
export function fetchCoachedUserNotes(token, userId) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.COACH_REQUEST_USER_NOTES });

    Api.fetchCoachedUserNotes(token, userId).then(
      (notes) => dispatch({
        type: ActionTypes.COACH_RECEIVED_USER_NOTES,
        payload: {
          notes
        }
      })

    ).catch(
      (err) => dispatch({
        type: ActionTypes.COACH_FAILED_USER_NOTES,
        payload: { error: err }
      })
    );
  };
}

export function createNewUserNote(token, userId, note) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.COACH_REQUEST_NEW_NOTE });

    Api.createNewUserNote(token, userId, note).then(
      (createdNote) => dispatch({
        type: ActionTypes.COACH_USER_NOTE_CREATED,
        payload: createdNote
      })
    ).catch(
      (err) => dispatch({
        type: ActionTypes.COACH_FAILED_NEW_NOTE,
        payload: { error: err }
      })
    );
  };
}

export function updateNewUserNote(token, userId, noteId, text) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.COACH_REQUEST_UPDATE_NOTE });

    Api.updateUserNote(token, userId, noteId, text).then(
      (updatedNote) => dispatch({
        type: ActionTypes.COACH_USER_NOTE_UPDATED,
        payload: updatedNote
      })
    ).catch(
      (err) => dispatch({
        type: ActionTypes.COACH_FAILED_UPDATE_NOTE,
        payload: { error: err }
      })
    );
  };
}

export function fetchDailyCheckins(token, userId) {
  return (dispatch) => {
    dispatch(_requestDailyCheckins());

    Api.fetchDailyCheckins(token, userId).then(
      (checkins) => dispatch(_receivedDailyCheckins(checkins))
    ).catch(
      (err) => dispatch(_failedDailyCheckins(err))
    );
  };
}

function _requestDailyCheckins() {
  return {
    type: ActionTypes.COACH_DAILY_CHECKINS_REQUEST
  };
}

function _receivedDailyCheckins(dailyCheckins) {
  return {
    type: ActionTypes.COACH_DAILY_CHECKINS_RECEIVED,
    payload: {
      dailyCheckins
    }
  };
}

function _failedDailyCheckins(error) {
  return {
    type: ActionTypes.COACH_DAILY_CHECKINS_FAILED,
    payload: { error }
  };
}

export function fetchWeeklyCheckins(token, userId) {
  return (dispatch) => {
    dispatch(_requestWeeklyCheckins());

    Api.fetchWeeklyCheckins(token, userId).then(
      (checkins) => dispatch(_receivedWeeklyCheckins(checkins))
    ).catch(
      (err) => dispatch(_failedWeeklyCheckins(err))
    );
  };
}

function _requestWeeklyCheckins() {
  return {
    type: ActionTypes.COACH_WEEKLY_CHECKINS_REQUEST
  };
}

function _receivedWeeklyCheckins(weeklyCheckins) {
  return {
    type: ActionTypes.COACH_WEEKLY_CHECKINS_RECEIVED,
    payload: {
      weeklyCheckins
    }
  };
}

function _failedWeeklyCheckins(error) {
  return {
    type: ActionTypes.COACH_WEEKLY_CHECKINS_FAILED,
    payload: { error }
  };
}

export function fetchJustSmokedEvents(token, userId) {
  return (dispatch) => {
    dispatch(_requestJustSmokedEvents());

    Api.fetchJustSmokedEvents(token, userId).then(
      (events) => dispatch(_receivedJustSmokedEvents(events))
    ).catch(
      (err) => dispatch(_failedJustSmokedEvents(err))
    );
  };
}


function _requestJustSmokedEvents() {
  return {
    type: ActionTypes.COACH_JUST_SMOKED_EVENTS_REQUEST
  };
}

function _receivedJustSmokedEvents(smokedEvents) {
  return {
    type: ActionTypes.COACH_JUST_SMOKED_EVENTS_RECEIVED,
    payload: {
      smokedEvents
    }
  };
}

function _failedJustSmokedEvents(error) {
  return {
    type: ActionTypes.COACH_JUST_SMOKED_EVENTS_FAILED,
    payload: { error }
  };
}


export function fetchCravings(token, userId) {
  return (dispatch) => {
    dispatch(_requestCravings());

    Api.fetchCravings(token, userId).then(
      (cravings) => dispatch(_receivedCravings(cravings))
    ).catch(
      (err) => dispatch(_failedCravings(err))
    );
  };
}


function _requestCravings() {
  return {
    type: ActionTypes.COACH_CRAVINGS_REQUEST
  };
}

function _receivedCravings(cravings) {
  return {
    type: ActionTypes.COACH_CRAVINGS_RECEIVED,
    payload: {
      cravings
    }
  };
}

function _failedCravings(error) {
  return {
    type: ActionTypes.COACH_CRAVINGS_FAILED,
    payload: { error }
  };
}
