import { Map } from 'immutable';
import { logger } from '../lib';
import { ActionTypes } from '../constants';

const initialState = {
  loading: false,
  error: null,
  messages: null,
  filterType: null
};

export default function pendingGroupMessages(state = Map(initialState), action) {
  const { payload, type } = action;

  switch (type) {
    case ActionTypes.COACH_REQUEST_GROUP_MESSAGES:
      return state.merge({
        loading: true
      });
    case ActionTypes.COACH_RECEIVED_GROUP_MESSAGES:
      return state.merge({
        messages: payload.messages,
        loading: false
      });
    case ActionTypes.COACH_FAILED_GROUP_MESSAGES: {
      logger.error('Failed to get group messages', payload.error);
      return state.merge({
        error: payload.error,
        loading: false
      });
    }
    case ActionTypes.COACH_MODERATE_GROUP_MESSAGES:
      return state.merge({
        loading: true
      });
    case ActionTypes.COACH_COMPLETED_MESSAGE_MODERATION:
      return state.merge({
        loading: false
      });
    case ActionTypes.COACH_FAILED_MESSAGE_MODERATION: {
      logger.error('Failed to moderate group messages', payload.error);
      return state.merge({
        error: payload.error,
        loading: false
      });
    }
    case ActionTypes.COACH_REQUEST_SEND_GROUP_MESSAGE:
      return state.merge({
        loading: true
      });
    case ActionTypes.COACH_GROUP_MESSAGE_SENT:
      return state.merge({
        messageSent: payload.message,
        loading: false
      });
    case ActionTypes.COACH_FAILED_SENDING_GROUP_MESSAGE:
      return state.merge({
        loading: false,
        error: payload.error
      });
    case ActionTypes.MESSAGE_FILTER_TYPE_CHANGE:
      return state.merge({
        filterType: payload.filterType
      });
    case ActionTypes.MESSAGES_WITH_DATE_RANGE_REQUESTED:
      return state.merge({
        loading: true
      });
    case ActionTypes.MESSAGES_WITH_DATE_RANGE_RECEIVED:
      return state.merge({
        loading: false,
        messages: payload.messages
      });
    case ActionTypes.MESSAGES_WITH_DATE_RANGE_FAILED:
      return state.merge({
        loading: false,
        error: payload.error
      });
    default:
      return state;
  }
}
