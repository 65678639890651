import { List, Map } from 'immutable';
import moment from 'moment';

const Columns = Object.freeze({
  ID: 'id',
  NAME: 'last_name',
  LOCATION: 'state_or_province',
  PHONE_NUMBER: 'phone_number',
  QUIT_START: 'start_date',
  CLIENT_NAME: 'client_name',
  SMS_STATUS: 'sms_disabled',
  QUIT_AID_STATUS: 'order_status'
});

const ColumnOrder = List.of(
  Columns.ID,
  Columns.NAME,
  Columns.LOCATION,
  Columns.PHONE_NUMBER,
  Columns.QUIT_START,
  Columns.CLIENT_NAME,
  Columns.SMS_STATUS,
  Columns.QUIT_AID_STATUS
);

const DefaultFilter = 'defaultFilter';

const FilterOrder = List.of(
  DefaultFilter,
  Columns.ID,
  Columns.NAME,
  Columns.LOCATION,
  Columns.PHONE_NUMBER,
  Columns.QUIT_START,
  Columns.CLIENT_NAME,
  Columns.SMS_STATUS,
  Columns.QUIT_AID_STATUS
);

const QuitAidStatus = Object.freeze({
  DEFAULT: 'none selected',
  DRAFT: 'draft',
  PROCESSING: 'processing',
  FILLED: 'filled',
  RECEIVED: 'received',
  REJECTED: 'rejected',
  CANCELED: 'canceled'
});

const QuitAidStatusOrder = List.of(
  QuitAidStatus.DEFAULT,
  QuitAidStatus.DRAFT,
  QuitAidStatus.PROCESSING,
  QuitAidStatus.FILLED,
  QuitAidStatus.RECEIVED,
  QuitAidStatus.REJECTED,
  QuitAidStatus.CANCELED
);

const QuitStartRange = Object.freeze({
  DEFAULT: 'none selected',
  LT_1WK: 'less than 1 week',
  LT_2WK: 'less than 2 weeks',
  LT_1MO: 'less than 1 month',
  LT_2MO: 'less than 2 months',
  GT_2MO: 'more than 2 months'
});

const QuitStartRangeOrder = List.of(
  QuitStartRange.DEFAULT,
  QuitStartRange.LT_1WK,
  QuitStartRange.LT_2WK,
  QuitStartRange.LT_1MO,
  QuitStartRange.LT_2MO,
  QuitStartRange.GT_2MO
);

const SMSStatusOptions = Object.freeze({
  DEFAULT: 'none selected',
  ENABLED: 'send',
  DISABLED: 'stop'
});

const SMSStatusOptionsOrder = List.of(
  SMSStatusOptions.DEFAULT,
  SMSStatusOptions.ENABLED,
  SMSStatusOptions.DISABLED
);

const LessThanDaysByQuitStartRange = Map({
  [QuitStartRange.LT_1WK]: 7,
  [QuitStartRange.LT_2WK]: 14,
  [QuitStartRange.LT_1MO]: 30,
  [QuitStartRange.LT_2MO]: 60
});

const DropdownDataSource = Map({
  [Columns.QUIT_START]: QuitStartRangeOrder,
  [Columns.QUIT_AID_STATUS]: QuitAidStatusOrder,
  [Columns.SMS_STATUS]: SMSStatusOptionsOrder
});

const today = moment().startOf('day');
const handleAsc = (list, asc, appendList) => {
  const _appendList = appendList || List();
  return asc ? list.concat(_appendList) : list.reverse().concat(_appendList);
};

const configsByColumnName = Map({
  [Columns.ID]: {
    title: 'ID',
    sortingMethod: (users, asc) => {
      const attr = 'id';
      const validList = users.sortBy((user) => user.get(attr));
      return handleAsc(validList, asc);
    }
  },
  [Columns.NAME]: {
    title: 'Name',
    sortingMethod: (users, asc) => {
      const primaryAttr = 'last_name';
      const secondaryAttr = 'first_name';
      const hasLastName = (user) => (user.get(primaryAttr) !== null && user.get(primaryAttr).length > 0);
      const hasFirstName = (user) => (user.get(secondaryAttr) !== null && user.get(secondaryAttr).length > 0);
      const firstAndLastList = users.filter(hasLastName).sortBy((user) => user.get(primaryAttr).toLowerCase());
      const firstOnlyList = users.filterNot(hasLastName).filter(hasFirstName).sortBy((user) => user.get(secondaryAttr).toLowerCase());
      const nullList = users.filterNot(hasLastName).filterNot(hasFirstName);
      return handleAsc(firstAndLastList, asc, handleAsc(firstOnlyList, asc, nullList));
    }
  },
  [Columns.LOCATION]: {
    title: 'Location',
    sortingMethod: (users, asc) => {
      const attr = 'state_or_province';
      const isValid = (user) => (user.get(attr) !== null && user.get(attr).length > 0);
      const validList = users.filter(isValid).sortBy((user) => user.get(attr).toLowerCase());
      const nullList = users.filterNot(isValid);
      return handleAsc(validList, asc, nullList);
    }
  },
  [Columns.PHONE_NUMBER]: {
    title: 'Phone Number',
    sortingMethod: (users, asc) => {
      const attr = 'phone_number';
      const isValid = (user) => (user.get(attr) !== null && user.get(attr).length > 0);
      const validList = users.filter(isValid).sortBy((user) => user.get(attr));
      const nullList = users.filterNot(isValid);
      return handleAsc(validList, asc, nullList);
    }
  },
  [Columns.QUIT_START]: {
    title: 'Quit Start',
    sortingMethod: (users, asc) => {
      const attr = 'start_date';
      const isValid = (user) => (user.get(attr) !== null && user.get(attr).length > 0);
      const validList = users.filter(isValid).sortBy((user) => today.diff(moment(user.get(attr)), 'hours'));
      const nullList = users.filterNot(isValid);
      return handleAsc(validList, asc, nullList);
    }
  },
  [Columns.CLIENT_NAME]: {
    title: 'Client',
    sortingMethod: (users, asc) => {
      const attr = 'client_name';
      const isValid = (user) => (user.get(attr) !== null && user.get(attr).length > 0);
      const validList = users.filter(isValid).sortBy((user) => user.get(attr).toLowerCase());
      const nullList = users.filterNot(isValid);
      return handleAsc(validList, asc, nullList);
    }
  },
  [Columns.SMS_STATUS]: {
    title: 'SMS Status',
    sortingMethod: (users, asc) => {
      const attr = 'sms_disabled';
      const validList = users.sortBy((user) => user.get(attr));
      return handleAsc(validList, asc);
    }
  },
  [Columns.QUIT_AID_STATUS]: {
    title: 'Quit Aid Status',
    sortingMethod: (users, asc) => {
      const attr = 'order_status';
      const isValid = (user) => (user.get(attr) !== null && user.get(attr).length > 0);
      const validList = users.filter(isValid).sortBy((user) => user.get(attr).toLowerCase());
      const nullList = users.filterNot(isValid);
      return handleAsc(validList, asc, nullList);
    }
  }
});

const configsByFilterName = Map({
  [DefaultFilter]: {
    title: 'default (all)',
    filterMethod: () => {
      return true;
    }
  },
  [Columns.ID]: {
    title: 'ID:',
    filterMethod: (user, filterValue) => {
      const userValue = user.get('id').toString();
      return !!userValue && userValue.startsWith(filterValue);
    }
  },
  [Columns.NAME]: {
    title: 'Name:',
    filterMethod: (user, filterValue) => {
      const firstName = user.get('first_name');
      const lastName = user.get('last_name');
      const firstNameMatch = !!firstName && firstName.toLowerCase().includes(filterValue);
      const lastNameMatch = !!lastName && lastName.toLowerCase().includes(filterValue);
      return firstNameMatch || lastNameMatch;
    }
  },
  [Columns.LOCATION]: {
    title: 'Location:',
    filterMethod: (user, filterValue) => {
      const userState = user.get('state_or_province');
      const userCountry = `${user.get('country') || ''}`.replace('United States of America', 'USA').trim();
      const stateMatch = !!userState && userState.toLowerCase().startsWith(filterValue);
      const countryMatch = !!userCountry && userCountry.toLowerCase().startsWith(filterValue);
      return stateMatch || countryMatch;
    }
  },
  [Columns.PHONE_NUMBER]: {
    title: 'Phone Number:',
    filterMethod: (user, filterValue) => {
      const userValue = user.get('phone_number');
      return !!userValue && userValue.includes(filterValue);
    }
  },
  [Columns.CLIENT_NAME]: {
    title: 'Client:',
    filterMethod: (user, filterValue) => {
      const userValue = user.get('client_name');
      return !!userValue && userValue.toLowerCase().includes(filterValue);
    }
  },
  [Columns.QUIT_START]: {
    title: 'Quit Start:',
    filterMethod: (user, filterValue) => {
      const userStart = moment(user.get('start_date'));
      if (filterValue === QuitStartRange.GT_2MO) {
        return today.diff(userStart, 'days') >= 60;
      }
      const lessThanValue = LessThanDaysByQuitStartRange.get(filterValue) || 0;
      return today.diff(userStart, 'days') <= lessThanValue;
    }
  },
  [Columns.SMS_STATUS]: {
    title: 'SMS Status:',
    filterMethod: (user, filterValue) => {
      const userSmsDisabled = user.get('sms_disabled');
      return filterValue === SMSStatusOptions.DISABLED === !!userSmsDisabled;
    }
  },
  [Columns.QUIT_AID_STATUS]: {
    title: 'Quit Aid Status:',
    filterMethod: (user, filterValue) => {
      const userValue = user.get('order_status');
      return !!userValue && userValue === filterValue;
    }
  }
});

export {
    Columns,
    ColumnOrder,
    configsByColumnName,
    DefaultFilter,
    FilterOrder,
    configsByFilterName,
    DropdownDataSource
  };
