import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import moment from 'moment';

import classnames from 'classnames';
import '../../styles/3ps/bootstrap.min.css';
import styles from '../../styles/coach-user.scss';


@connect( (state) => {
  return {
    coachedUser: state.coachedUser
  };
})
export default class CoachUserDetail extends Component {

  render() {
    const loading = this.props.coachedUser.get('loading');
    if (loading || loading === null) {
      return null;
    }


    const coachedUser = this.props.coachedUser.get('user').toJS();
    const smokerProfile = coachedUser.smoker_profile || {};
    const quitMotives = coachedUser.quit_motives || [];

    // FIXME? it is likely that the first of the list is not the correct action...
    const fagerstrom = (coachedUser.fagerstrom_responses || []).shift() || {};
    const quitPlan = coachedUser.quit_plans.find((qp) => qp.is_active);

    const colorTag = (color, txt) => (
      <span className={styles['alert_' + color]}>{txt}</span>
    );

    const drawProps = !quitPlan ? {} : {
      start_date: colorTag('gray', moment(quitPlan.start_date).format('MM/DD/YYYY')),
      quit_date: moment(quitPlan.quit_date).format('MM/DD/YYYY'),
      day_of_plan: moment(new Date()).diff(quitPlan.start_date, 'days'),
      plan_type: (quitPlan.plan_type === 1 ? 'Over Time' : 'Cold Turkey'),

      quit_medication: (smokerProfile.is_open_to_quit_aids ? colorTag('green', 'Yes, willing') : colorTag('red', 'Not at this time')),
      num_years_smoked: smokerProfile.num_years_smoked,
      cigs_per_day: fagerstrom.cigs_per_day,
      first_cig_time_range: fagerstrom.first_cig_time_range && fagerstrom.first_cig_time_range.replace(/_/g, ' '),
      first_cig_favorite: (fagerstrom.first_cig_is_favorite ? colorTag('red', 'I do') : colorTag('green', 'I don\'t')),
      waking_hours_trouble: (fagerstrom.more_frequent_in_first_hours ? colorTag('red', 'I do') : colorTag('green', 'I don\'t')),
      forbidden_difficult: (fagerstrom.is_difficult_when_forbidden ? colorTag('red', 'It is') : colorTag('green', 'It isn\'t')),
      smoking_when_ill: (fagerstrom.smokes_when_ill ? colorTag('red', 'I do') : colorTag('green', 'I don\'t')),
      cigQuota: this.getCigQuota(quitPlan, fagerstrom)
    };

    if (new Date().getTime() > new Date(drawProps.quit_date)) {
      drawProps.day_of_plan = 'post-quit';
    }

    const scrollBoxHeight = (window.innerHeight - 190) + 'px';

    return (
      <div className={classnames(styles.detailsBox, 'col-sm-10')}>

        {/* FIXME: need a sexy subnav component for user pages */}
        <nav className="navbar navbar-inverse"
             style={{ margin: 0 }}>
          <ul className="nav navbar-nav">
            <li className={classnames('nav-item', 'active', styles.activeNav)}>
              <Link to={`/user/${coachedUser.id}`}
                    className="nav-link">
                Profile
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`/user/${coachedUser.id}/messages`}
                    className="nav-link">
                Messages
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`/user/${coachedUser.id}/clickstream`}
                    className="nav-link">
                Clickstream
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`/user/${coachedUser.id}/notes`}
                    className="nav-link">
                Notes
              </Link>
            </li>
          </ul>
        </nav>

        <div className={styles.detailsBoxContent}
             style={{ height: scrollBoxHeight, overflowY: 'scroll' }}>
          <h3>Quit plan</h3>

          <div className="row">
            <div className="col-sm-2 text-center">
              <div className={styles.statusRowTitle}>Commitment date</div>
              <div className={styles.statusRowValue}>{drawProps.start_date}</div>
            </div>

            <div className="col-sm-2 text-center">
              <div className={styles.statusRowTitle}>Quit date</div>
              <div className={styles.statusRowValue}>{drawProps.quit_date}</div>
            </div>

            <div className="col-sm-2 text-center">
              <div className={styles.statusRowTitle}>Day of plan</div>
              <div className={styles.statusRowValue}>{drawProps.day_of_plan}</div>
            </div>

            <div className="col-sm-2 text-center">
              <div className={styles.statusRowTitle}>Today's quota</div>
              <div className={styles.statusRowValue}>{drawProps.cigQuota}</div>
            </div>

            <div className="col-sm-2 text-center">
              <div className={styles.statusRowTitle}>Plan Type</div>
              <div className={styles.statusRowValue}>{drawProps.plan_type}</div>
            </div>
          </div>

          <div className={classnames('row', styles.motivesTable)}>
            <div id="motivesLeft"
                 className={classnames('col-lg-9', styles.motivesLeft)}>
              <div className="row">
                <div className="col-lg-6">
                  <h3>Quit motives</h3>
                  <ul>
                    { quitMotives.map(this.renderMotive.bind(this)) }
                  </ul>
                </div>
                <div className="col-lg-6" />
              </div>
            </div>
            <div id="motivesRight"
                 className={classnames('col-lg-3', styles.motivesRight)}>
              <div className={styles.nrtTitle}>Quit medication</div>
              <div className={styles.nrtValue}>{ drawProps.quit_medication }</div>
            </div>
          </div>


          <div className="row">
            <div className="col-sm-3 text-center">
              <div className={styles.baseRowValue}>{drawProps.num_years_smoked}</div>
              <div className={styles.baseRowTitle}>of years<br />smoking</div>
            </div>
            <div className="col-sm-3  text-center">
              <div className={styles.baseRowValue}>{drawProps.cigs_per_day}</div>
              <div className={styles.baseRowTitle}>cigarettes<br />per day</div>
            </div>
            <div className="col-sm-3  text-center">
              <div className={styles.baseRowValue}>{drawProps.first_cig_time_range}</div>
              <div className={styles.baseRowTitle}>minutes after waking,<br />time until smoking</div>
            </div>
            <div className="col-sm-3  text-center">
              <div className={styles.baseRowValue}>{drawProps.first_cig_favorite}</div>
              <div className={styles.baseRowTitle}>find giving up the 1st<br />is the hardest</div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4  text-center">
              <div className={styles.baseRowValue}>{drawProps.waking_hours_trouble}</div>
              <div className={styles.baseRowTitle}>smoke more in the<br />hours after waking</div>
            </div>
            <div className="col-sm-4  text-center">
              <div className={styles.baseRowValue}>{drawProps.forbidden_difficult}</div>
              <div className={styles.baseRowTitle}>hard to not smoke in<br />forbidden places</div>
            </div>
            <div className="col-sm-4  text-center">
              <div className={styles.baseRowValue}>{drawProps.smoking_when_ill}</div>
              <div className={styles.baseRowTitle}>smoke even when I'm<br />in bed and ill</div>
            </div>
          </div>

        </div>
      </div>
    );
  }

  getCigQuota = (quitPlan, fagerstrom) => {
    if (!quitPlan) {
      return 0;
    }

    const daysUntilQuit = moment(quitPlan.quit_date).diff(new Date(), 'days');
    const planInDays = moment(quitPlan.quit_date).diff(quitPlan.start_date, 'days');
    return (daysUntilQuit > 0) ? (quitPlan.plan_type > 0) ? Math.ceil((fagerstrom.cigs_per_day / planInDays) * daysUntilQuit) : fagerstrom.cigs_per_day : 0;
  };

  renderMotive(motive) {
    return (
      <li key={motive.id}>{ motive.content }</li>
    );
  }
}
