// nav actions
export const REQUIRE_LOGIN = 'REQUIRE_LOGIN';
export const HANDLED_LOGIN_REDIRECT = 'HANDLED_LOGIN_REDIRECT';

// user actions
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const RECEIVED_LOGIN = 'RECEIVED_LOGIN';
export const FAILED_LOGIN = 'FAILED_LOGIN';
export const DID_LOGOUT = 'DID_LOGOUT';

export const REQUEST_TEMPORARY_PASSWORD = 'REQUEST_TEMPORARY_PASSWORD';
export const TEMPORARY_PASSWORD_REQUEST_SUCCESS = 'TEMPORARY_PASSWORD_REQUEST_SUCCESS';
export const TEMPORARY_PASSWORD_REQUEST_FAIL = 'TEMPORARY_PASSWORD_REQUEST_FAIL';

export const COACH_REQUEST_USER_LIST = 'COACH_REQUEST_USER_LIST';
export const COACH_RECEIVED_USER_LIST = 'COACH_RECEIVED_USER_LIST';
export const COACH_FAILED_USER_LIST = 'COACH_FAILED_USER_LIST';

export const COACH_MODERATE_GROUP_MESSAGES = 'COACH_MODERATE_GROUP_MESSAGES';
export const COACH_COMPLETED_MESSAGE_MODERATION = 'COACH_COMPLETED_MESSAGE_MODERATION';
export const COACH_FAILED_MESSAGE_MODERATION = 'COACH_FAILED_MESSAGE_MODERATION';

export const COACH_REQUEST_USER = 'COACH_REQUEST_USER';
export const COACH_RECEIVED_USER = 'COACH_RECEIVED_USER';
export const COACH_FAILED_USER = 'COACH_FAILED_USER';

export const COACH_REQUEST_CLIENT_CHANGE = 'COACH_REQUEST_CLIENT_CHANGE';
export const COACH_CLIENT_CHANGE_SUCCESS = 'COACH_CLIENT_CHANGE_SUCCESS';
export const COACH_CLIENT_CHANGE_FAIL = 'COACH_CLIENT_CHANGE_FAIL';

export const UPDATE_COACHED_USER_IN_LIST_FOR_NEW_CLIENT = 'UPDATE_COACHED_USER_IN_LIST_FOR_NEW_CLIENT';

export const COACH_REQUEST_USER_MESSAGES = 'COACH_REQUEST_USER_MESSAGES';
export const COACH_RECEIVED_USER_MESSAGES = 'COACH_RECEIVED_USER_MESSAGES';
export const COACH_FAILED_USER_MESSAGES = 'COACH_FAILED_USER_MESSAGES';

export const COACH_REQUEST_USER_NOTES = 'COACH_REQUEST_USER_NOTES';
export const COACH_RECEIVED_USER_NOTES = 'COACH_RECEIVED_USER_NOTES';
export const COACH_FAILED_USER_NOTES = 'COACH_FAILED_USER_NOTES';

export const COACH_REQUEST_NEW_NOTE = 'COACH_REQUEST_NEW_NOTE';
export const COACH_USER_NOTE_CREATED = 'COACH_USER_NOTE_CREATED';
export const COACH_FAILED_NEW_NOTE = 'COACH_FAILED_NEW_NOTE';

export const COACH_REQUEST_UPDATE_NOTE = 'COACH_REQUEST_UPDATE_NOTE';
export const COACH_USER_NOTE_UPDATED = 'COACH_USER_NOTE_UPDATED';
export const COACH_FAILED_UPDATE_NOTE = 'COACH_FAILED_UPDATE_NOTE';

export const COACH_REQUEST_GROUP_MESSAGES = 'COACH_REQUEST_GROUP_MESSAGES';
export const COACH_RECEIVED_GROUP_MESSAGES = 'COACH_RECEIVED_GROUP_MESSAGES';
export const COACH_FAILED_GROUP_MESSAGES = 'COACH_FAILED_GROUP_MESSAGES';

export const COACH_REQUEST_SEND_MESSAGE = 'COACH_REQUEST_SEND_MESSAGE';
export const COACH_MESSAGE_SENT = 'COACH_MESSAGE_SENT';
export const COACH_FAILED_SENDING_MESSAGE = 'COACH_FAILED_SENDING_MESSAGE';

export const COACH_REQUEST_SEND_GROUP_MESSAGE = 'COACH_REQUEST_SEND_GROUP_MESSAGE';
export const COACH_GROUP_MESSAGE_SENT = 'COACH_GROUP_MESSAGE_SENT';
export const COACH_FAILED_SENDING_GROUP_MESSAGE = 'COACH_FAILED_SENDING_GROUP_MESSAGE';

export const MESSAGE_FILTER_TYPE_CHANGE = 'MESSAGE_FILTER_TYPE_CHANGE';
export const MESSAGES_WITH_DATE_RANGE_REQUESTED = 'MESSAGES_WITH_DATE_RANGE_REQUESTED';
export const MESSAGES_WITH_DATE_RANGE_RECEIVED = 'MESSAGES_WITH_DATE_RANGE_RECEIVED';
export const MESSAGES_WITH_DATE_RANGE_FAILED = 'MESSAGES_WITH_DATE_RANGE_FAILED';

export const COACH_JUST_SMOKED_EVENTS_REQUEST = 'COACH_JUST_SMOKED_EVENTS_REQUEST';
export const COACH_JUST_SMOKED_EVENTS_RECEIVED = 'COACH_JUST_SMOKED_EVENTS_RECEIVED';
export const COACH_JUST_SMOKED_EVENTS_FAILED = 'COACH_JUST_SMOKED_EVENTS_FAILED';

export const COACH_DAILY_CHECKINS_REQUEST = 'COACH_DAILY_CHECKINS_REQUEST';
export const COACH_DAILY_CHECKINS_RECEIVED = 'COACH_DAILY_CHECKINS_RECEIVED';
export const COACH_DAILY_CHECKINS_FAILED = 'COACH_DAILY_CHECKINS_FAILED';

export const COACH_WEEKLY_CHECKINS_REQUEST = 'COACH_WEEKLY_CHECKINS_REQUEST';
export const COACH_WEEKLY_CHECKINS_RECEIVED = 'COACH_WEEKLY_CHECKINS_RECEIVED';
export const COACH_WEEKLY_CHECKINS_FAILED = 'COACH_WEEKLY_CHECKINS_FAILED';

export const COACH_CRAVINGS_REQUEST = 'COACH_CRAVINGS_REQUEST';
export const COACH_CRAVINGS_RECEIVED = 'COACH_CRAVINGS_RECEIVED';
export const COACH_CRAVINGS_FAILED = 'COACH_CRAVINGS_FAILEDss';
