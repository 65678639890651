/* eslint react/self-closing-comp:0 */

import React from 'react';
import { Route, IndexRoute, Redirect } from 'react-router';

import { NamedRoutes } from './lib';
import { RequireAuth, Coach } from './components';
import { LoginPage, CoachPage, CoachedUserPage, ForgotPasswordPage, MessagesPage } from './pages';

export default (
  <div>
    <Redirect from="/"
              to={NamedRoutes.COACH_USER_LIST} />
    <Route path={NamedRoutes.LOGIN}
           component={LoginPage} />
    <Route path={NamedRoutes.FORGOT_PASSWORD}
           component={ForgotPasswordPage} />

    <Route component={RequireAuth}>
      <Route component={CoachPage} >

        <Route path={NamedRoutes.COACH_USER_LIST} >
          <IndexRoute component={Coach.CoachUserList} />
          <Route path={NamedRoutes.COACH_USER_DETAIL}>
            <Route component={CoachedUserPage}>
              <IndexRoute component={Coach.CoachUserDetail} />
              <Route path={NamedRoutes.COACH_USER_MESSAGES}
                     component={Coach.CoachUserMessages} />
              <Route path={NamedRoutes.COACH_USER_NOTES}
                     component={Coach.CoachUserNotes} />
              <Route path={NamedRoutes.COACH_USER_CLICKSTREAM}
                     component={Coach.CoachUserClickstream} />
            </Route>
          </Route>
        </Route>

        <Route path={NamedRoutes.COACH_GROUP_MESSAGES}
               component={MessagesPage} />

      </Route>
    </Route>

  </div>
);
