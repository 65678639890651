import React, { Component } from 'react';
import { Map, List } from 'immutable';

export function propChangeHandler(handler) {
  return (WrappedComponent) => (
    class PropChangeHandler extends Component {
      componentWillMount() {
        handler(this.props);
      }

      componentWillReceiveProps(nextProps) {
        handler(nextProps);
      }

      render() {
        return React.createElement(WrappedComponent, this.props);
      }
    }
  );
}
// #### dataDispatch() - decorator
//
// Born from the one above, but moves some more of the boilerplate required in the components into this so it can
// dispatch required calls, provide a doReloadData() method and a way to extend the doReload method (for example
// confirm that the new user id is the same as the current dataset) and be kept up to date with event state.
//
// Helping classes only care about the shit that they care about and not the large chaining of properties and
// state/lifecycle management.
//
//
// helper functions for the upgraded prop change handler below
function _runReloadData(props, objectProp, childProp, doReload) {
  if (!props) return true;
  const obj = props[objectProp];
  if (!obj) return true;
  if (obj.get('error')) return false;
  const child = obj.get(childProp);
  // N.B.: This is a hack based on our states initializing to null. Any reassignment of that state changes it to not null.
  return (child === null) ? true : (doReload && doReload(child));
}


function _dispatcher(props, objProp, childProp, caller, reloader) {
  if (!(props[objProp] && props[objProp].get('loading')) && _runReloadData(props, objProp, childProp, reloader)) {
    if (!caller) {
      /* eslint no-console: 0 */
      console.error('no dispatch callback defined for ' + objProp);
      return;
    }
    props.dispatch(caller());
  }
}

function _runDispatchers(dataSets, props, thus) {
  const dataSetsMap = Map(dataSets);
  dataSetsMap.forEach( (value, property) => {
    if ({}.hasOwnProperty.call(dataSets, property)) {
      const ds = value;
      const caller = (ds.loader) ? thus[ds.loader].bind(thus, props) : null;
      const reloader = (ds.doReload) ? thus[ds.doReload].bind(thus) : null;
      _dispatcher(props, property, ds.childProp, caller, reloader);
    }
  });
}

/**
 * TODO: This mixer isn't respecting existing properties at the moment, would be nice to keep adding to the stack of
 * functions and have them still execute as expected (although it's making some spaghetti).
 */
export function classPrototypeMixin(mixer) {
  const typeTag = Symbol('isa');

  function _mixin(clazz) {
    const mixerKeys = List(Reflect.ownKeys(mixer));
    mixerKeys.forEach((property) => {
      Object.defineProperty(clazz.prototype, property, {
        value: mixer[property],
        writable: true
      });
    });
    Object.defineProperty(clazz.prototype, typeTag, { value: true });
    return clazz;
  }
  Object.defineProperty(_mixin, Symbol.hasInstance, {
    value: (i) => !!i[typeTag]
  });

  return _mixin;
}

export function dataDispatch(config) {
  return classPrototypeMixin({
    componentWillMount: function _componentWillMount() {
      _runDispatchers(config, this.props, this);
    },
    componentWillReceiveProps: function _componentWillReceiveProps(props) {
      _runDispatchers(config, props, this);
    }
  });
}
