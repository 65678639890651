/* global __BUILD_ENV__, __PRODUCTION_BUILD_ENV__, __BETA_BUILD_ENV__, __ALPHA_BUILD_ENV__, __DEVELOPMENT_BUILD_ENV__, __LOCAL_BUILD_ENV__, __DEV_API_ROOT__ */

const config = getConfig();

export default config;

function getConfig() {

  switch ( __BUILD_ENV__ ) {
    case __PRODUCTION_BUILD_ENV__:
      return {
        apiRoot: 'https://ct101-api.clicktherapeutics.com/api'
      };
    case __BETA_BUILD_ENV__:
      return Object.assign({
        apiRoot: 'https://ct101-api.beta.clicktherapeuticsdev.com/api'
      });
    case __ALPHA_BUILD_ENV__:
      return Object.assign({
        apiRoot: 'https://ct101-api.alpha.clicktherapeuticsdev.com/api'
      });
    case __DEVELOPMENT_BUILD_ENV__:
      return Object.assign({
        apiRoot: 'https://ct101-api.development.clicktherapeuticsdev.com/api'
      });
    case __LOCAL_BUILD_ENV__:
      return Object.assign({
        apiRoot: __DEV_API_ROOT__ + '/api'
      });
    default:
      throw new Error('Unknown environment: ' + __BUILD_ENV__);
  }
}
