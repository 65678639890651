import { Map } from 'immutable';
import { logger } from '../lib';
import { ActionTypes } from '../constants';

const initialState = {
  loading: false,
  error: null,
  messages: null
};

export default function coachedUserMessages(state = Map(initialState), action) {
  const { payload } = action;

  switch (action.type) {
    case ActionTypes.COACH_RECEIVED_USER: {
      // making sure that the the messages are reset if the user changes
      return state.merge({ ...initialState });
    }
    case ActionTypes.COACH_REQUEST_USER_MESSAGES:
      return state.merge({ ...initialState, loading: true });

    case ActionTypes.COACH_RECEIVED_USER_MESSAGES:
      return state.merge({ ...initialState, messages: payload.messages });

    case ActionTypes.COACH_FAILED_USER_MESSAGES: {
      logger.error('Failed to get coached user messages', payload.error);
      return state.merge({ ...initialState, error: payload.error });
    }

    case ActionTypes.COACH_MESSAGE_SENT: {
      return state.merge({ ...initialState, messages: state.get('messages').push(new Map(payload)) });
    }
    default:
      return state;
  }
}
