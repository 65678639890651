import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

const { PropTypes } = React;

export default function propTypes(types) {
  return (WrappedComponent) => (
    class PropTypesWrapper extends Component {
      static propTypes = {
        ...types
      };

      render() {
        return React.createElement(WrappedComponent, this.props);
      }
    }
  );
}

propTypes.Immutable = ImmutablePropTypes;
Object.assign(propTypes, PropTypes);

const userPropType = ImmutablePropTypes.contains({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string.isRequired,
  profilePicUrl: PropTypes.string
});

Object.assign(propTypes, {
  user: userPropType,
  quitter: userPropType
});
