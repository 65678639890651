import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { dataDispatch } from '../lib/react-annotations';
import { ProfilePic } from '../components';
import { CoachActions } from '../actions';

import classnames from 'classnames';
import '../styles/3ps/bootstrap.min.css';
import styles from '../styles/coach-user.scss';

const CLICK_CLIENT_ID = 1;

@connect( (state) => {
  const { userData, coachedUser } = state;
  return {
    token: userData.get('sessionToken'),
    user: userData.get('user'),
    coachedUser
  };
})
@dataDispatch({
  coachedUser: {
    childProp: 'user',
    loader: 'loadCoachedUser',
    doReload: 'doReloadCoachedUser'
  }
})
export default class CoachedUserPage extends Component {

  render() {
    const loading = this.props.coachedUser.get('loading');
    if (loading || loading === null) {
      return (
        <div>Loading...</div>
      );
    }

    const coachedUser = this.props.coachedUser.get('user').toJS();
    const age = moment(coachedUser.birthday).fromNow().split(' ')[0];

    const scrollBoxHeight = (window.innerHeight - 160) + 'px';

    return (
      <div>
        <div className={classnames(styles.body, styles.row, 'row')}>
          <div className={classnames(styles.heroBox, 'col-sm-2')}
                style={{ height: scrollBoxHeight }}>
            <ProfilePic url={coachedUser.profilePicUrl}
                        size="large" />
            <div className={styles.fullName}>
              { coachedUser.first_name }&nbsp;
              { coachedUser.last_name }
            </div>
            <div className={styles.subInfo}>{ coachedUser.birthday }&nbsp;({ age })</div>
            <div className={styles.subInfo}>{ coachedUser.gender }</div>
            <div className={styles.subInfo}>{coachedUser.state_or_province}, {coachedUser.country === 'United States of America' ? 'USA' : coachedUser.country}</div>

            <div className={styles.subInfo}>
              Phone Number:<br />
              <span className={styles.additionalInfo}>{ coachedUser.phone_number }</span>
            </div>

            <div className={styles.subInfo}>
              SMS Status:<br />
              <span className={styles.phoneInfo}>
              {coachedUser.sms_disabled
                ? <span className={styles.alert_red}>Stop</span>
                : <span className={styles.alert_green}>Send</span>
              }
              </span>
            </div>

            <div className={styles.subInfo}>
              Click's Outbound Number:<br />
              <span className={styles.additionalInfo}>{ coachedUser.sms_origin_phone }</span>
            </div>

            <div className={styles.subInfo}>
              Client <br />
              <span className={styles.additionalInfo}>{ this.props.coachedUser.getIn([ 'user', 'client', 'name' ]) }</span>
            </div>
            {
              this.doShowDumpLink() ?
              (
                <a className={styles.dumpToClickClient}
                   onClick={this.dumpUserToClickClient}
                   role="button"
                   tabIndex="0">
                  Dump to Click Client
                </a>
              ) : null
            }

          </div>

          {this.props.children}

        </div>
      </div>
    );
  }

  dumpUserToClickClient = () => {
    const { dispatch, coachedUser, token } = this.props;
    const coachedUserId = coachedUser.getIn([ 'user', 'id' ]);
    dispatch(CoachActions.setUserToClickClient(coachedUserId, token));
  }

  doShowDumpLink = () => {
    const { coachedUser } = this.props;
    const clientId = coachedUser.getIn([ 'user', 'client', 'id' ]);
    return clientId && clientId !== CLICK_CLIENT_ID; // TODO: Remove dump link or make sure coach dashboard user is coachAdmin (coachAdmin flags requires API changes)
  }

  loadCoachedUser() {
    return CoachActions.fetchCoachedUser(this.props.token, this.props.params.userId);
  }

  doReloadCoachedUser(userObj) {
    return (userObj.get('id') !== parseInt(this.props.params.userId, 10));
  }
}
