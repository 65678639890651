import LoginPage from './login';
import CoachPage from './coach';
import CoachedUserPage from './coach-user';
import ForgotPasswordPage from './forgot-password';
import MessagesPage from './messages';

export {
  LoginPage,
  CoachPage,
  CoachedUserPage,
  ForgotPasswordPage,
  MessagesPage
};
