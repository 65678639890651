exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./utils.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss"), "");

// Module
exports.push([module.id, ".coach-header__body___3enWe {\n  height: 75px;\n  padding: 0; }\n\n.coach-header__logo___2fk8F {\n  float: left;\n  height: 74px; }\n\n.coach-header__coach___1YMjC {\n  padding-right: 20px;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n  align-items: center;\n  -ms-flex-pack: center;\n  justify-content: center;\n  height: 75px;\n  float: right; }\n\n.coach-header__coachBody___2nGga {\n  text-align: center;\n  color: #f00; }\n\n.coach-header__name___10rl_ {\n  font-size: 16px; }\n\n.coach-header__email___YOR_E {\n  font-size: 14px; }\n", ""]);

// Exports
exports.locals = {
	"body": "coach-header__body___3enWe " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["message"] + " " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./utils.scss").locals["clearfix"] + "",
	"logo": "coach-header__logo___2fk8F",
	"coach": "coach-header__coach___1YMjC",
	"coachBody": "coach-header__coachBody___2nGga",
	"name": "coach-header__name___10rl_ " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["name"] + "",
	"email": "coach-header__email___YOR_E " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["emailInfo"] + ""
};