import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { dataDispatch } from '../../lib/react-annotations';
import { CoachActions } from '../../actions';

import classnames from 'classnames';
import '../../styles/3ps/bootstrap.min.css';
import styles from '../../styles/coach-clickstream.scss';


@connect( (state) => {
  const { userData } = state;
  return {
    token: userData.get('sessionToken'),
    user: userData.get('user'),
    coachedUser: state.coachedUser,
    clickstreamCravings: state.clickstreamCravings,
    clickstreamDailyCheckins: state.clickstreamDailyCheckins,
    clickstreamWeeklyCheckins: state.clickstreamWeeklyCheckins,
    clickstreamSmokedEvents: state.clickstreamSmokedEvents
  };
})
@dataDispatch({
  clickstreamCravings: { childProp: 'cravings', loader: 'fetchCravings' },
  clickstreamDailyCheckins: { childProp: 'dailyCheckins', loader: 'fetchDailyCheckins' },
  clickstreamWeeklyCheckins: { childProp: 'weeklyCheckins', loader: 'fetchWeeklyCheckins' },
  clickstreamSmokedEvents: { childProp: 'smokedEvents', loader: 'fetchJustSmokedEvents' }
})
export default class CoachUserClickstream extends Component {

  render() {
    if ( this.props.coachedUser.get('loading') ||
         this.props.clickstreamCravings.get('loading') ||
         this.props.clickstreamDailyCheckins.get('loading') ||
         this.props.clickstreamWeeklyCheckins.get('loading') ||
         this.props.clickstreamSmokedEvents.get('loading') ||
         !this.satisfyDataRequirements()
       ) {
      return (
        <div>Loading...</div>
      );
    }

    const coachedUser = this.props.coachedUser.get('user').toJS();

    const now = new Date().getTime();
    const timespan = { start: now, finish: now };

    const cravings = this.props.clickstreamCravings.get('cravings').toJS();
    const dailyCheckins = this.props.clickstreamDailyCheckins.get('dailyCheckins').toJS();
    const weeklyCheckins = this.props.clickstreamWeeklyCheckins.get('weeklyCheckins').toJS();
    const smokedEvents = this.props.clickstreamSmokedEvents.get('smokedEvents').toJS();

    [ cravings, dailyCheckins, weeklyCheckins, smokedEvents ].forEach((list) => list.forEach((item) => {
      const created = new Date(item.created_at);
      const time = created.getTime();
      if (time < timespan.start) timespan.start = time;
      else if (time > timespan.finish) timespan.finish = time;
    }));

    const scale = 5000 / (timespan.finish - timespan.start);

    return (
      <div className={classnames(styles.detailsBox, 'col-sm-10')}>

        {/* TODO: need a sexy subnav component for user pages */}
        <nav className="navbar navbar-inverse"
             style={{ margin: 0 }}>
          <ul className="nav navbar-nav">
            <li className="nav-item">
              <Link to={`/user/${coachedUser.id}`}
                    className="nav-link">
                Profile
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`/user/${coachedUser.id}/messages`}
                    className="nav-link">
                Messages
              </Link>
            </li>
            <li className={classnames('nav-item', 'active', styles.activeNav)}>
              <Link to={`/user/${coachedUser.id}/clickstream`}
                    className="nav-link">
                Clickstream
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`/user/${coachedUser.id}/notes`}
                    className="nav-link">
                Notes
              </Link>
            </li>
          </ul>
        </nav>
        <div className={styles.contentBox}>
          <h2><strong>Clickstream</strong></h2>

          <div className="row" >
            <div className={classnames(' col-sm-2', styles.streamTitles)}>
              <div className={classnames('row', styles.track)}><br />date/time:</div>
              <div className={classnames('row', styles.track)}><strong>Cravings</strong></div>
              <div className={classnames('row', styles.track)}><strong>Smoked Events</strong></div>
              <div className={classnames('row', styles.track)}><strong>Daily Checkins</strong></div>
              <div className={classnames('row', styles.track)}><strong>Weekly Checkins</strong></div>
            </div>
            <div className={classnames(' col-sm-10', styles.streamData)}>
              <div className={classnames('row', styles.track, styles.streamTitles)}>&nbsp;</div>
              {this.drawTrack.bind(this)(scale, cravings, styles.bulletOne)}
              {this.drawTrack.bind(this)(scale, smokedEvents, styles.bulletFour)}
              {this.drawTrack.bind(this)(scale, dailyCheckins, styles.bulletTwo)}
              {this.drawTrack.bind(this)(scale, weeklyCheckins, styles.bulletThree)}
            </div>
          </div>
        </div>
      </div>
    );
  }


  drawTrack(scale, data, bulletStyle) {
    const tagList = [ (
      <div style={{ float: 'left', height: '105px', width: '50px' }}
           key={'base'}>
        &nbsp;
      </div>
    ) ];

    data.forEach((dat, idx, ary) => {
      tagList.push(
        <div className={bulletStyle}
             key={idx}
          >&nbsp;
        </div>
      );

      if (idx < ary.length - 1) {
        const thus = new Date(dat.created_at).getTime();
        const next = new Date(ary[idx + 1].created_at).getTime();
        tagList.push(
          <div style={{ float: 'left', height: '105px', width: Math.round(scale * (next - thus)) + 'px' }}
               key={idx}>
            &nbsp;
          </div>
        );
      }
    });

    return (
      <div className={classnames('row', styles.track)}
           style={{ width: '10000px' }}>
        {tagList}
      </div>
    );
  }


  fetchCravings() {
    return CoachActions.fetchCravings(this.props.token, this.props.params.userId);
  }

  fetchDailyCheckins() {
    return CoachActions.fetchDailyCheckins(this.props.token, this.props.params.userId);
  }

  fetchWeeklyCheckins() {
    return CoachActions.fetchWeeklyCheckins(this.props.token, this.props.params.userId);
  }

  fetchJustSmokedEvents() {
    return CoachActions.fetchJustSmokedEvents(this.props.token, this.props.params.userId);
  }

  satisfyDataRequirements() {
    return this.props.clickstreamCravings.get('cravings') &&
    this.props.clickstreamDailyCheckins.get('dailyCheckins') &&
    this.props.clickstreamWeeklyCheckins.get('weeklyCheckins') &&
    this.props.clickstreamSmokedEvents.get('smokedEvents');
  }
}
