import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import moment from 'moment';
import { List } from 'immutable';

import { dataDispatch } from '../../lib/react-annotations';
import { MessagesActions } from '../../actions';

import classnames from 'classnames';
import '../../styles/3ps/bootstrap.min.css';
import styles from '../../styles/messaging.scss';

const MessageFilterTypes = Object.freeze({
  ONE_DAY: 'ONE_DAY',
  TWO_DAYS: 'TWO_DAYS',
  ONE_WEEK: 'ONE_WEEK',
  GROUP: 'GROUP'
});

const DEFAULT_MESSAGE_FILTER_TYPE = MessageFilterTypes.GROUP;

const TitleByFilterType = {
  [MessageFilterTypes.ONE_DAY]: '24hrs',
  [MessageFilterTypes.TWO_DAYS]: '2 days',
  [MessageFilterTypes.ONE_WEEK]: '7 days',
  [MessageFilterTypes.GROUP]: 'Group Messages'
};

@connect( (state) => {
  const { userData } = state;
  return {
    token: userData.get('sessionToken'),
    messagesData: state.messages
  };
})
@dataDispatch({
  messagesData: {
    childProp: 'messages',
    loader: 'fetchMessageActionForFilterType'
  }
})
export default class Messages extends Component {
  render() {
    // TODO: when the server side finishes moving from lame enums to constants, this can go back
    const messageTypes = [ 'outbound', 'inbound', 'push' ];

    const { messagesData } = this.props;
    if ( messagesData && messagesData.get('loading') ) {
      return (
        <div>
          Coach dashboard group messages page.
        </div>
      );
    }

    const messages = messagesData.get('messages') ? messagesData.get('messages').toJS() : [];

    // TODO: complete transisiont from lame enum to string constant
    const messagesWithStringMessageTypes = messages.map((message) => {
      const messageTypeEnumValue = message.message_type;
      return {
        ...message,
        message_type: messageTypes[messageTypeEnumValue]
      };
    });

    const scrollBoxBottomMargin = 250;
    const scrollBoxHeight = (window.innerHeight - scrollBoxBottomMargin) + 'px';

    return (
      <div>
        {this.renderNavbar()}
        <div className="row">
          <div className="col-lg-9">
            {/* MESSAGE LIST */}
            <div className={styles.messagesListBox}>
              <div ref="message_scroll_box"
                   style={{ height: scrollBoxHeight, overflowY: 'scroll' }}>
                { messagesWithStringMessageTypes.map(this.renderMessage.bind(this, this.getMessageFilterType() === MessageFilterTypes.GROUP)) }
              </div>
            </div>
          </div>
          <div className={classnames( 'col-lg-3', styles.motivesRight )}>
            {this.renderMessageFilterTools()}
          </div>
        </div>
      </div>
    );
  }

  renderNavbar = () => {
    return (
      <nav className="navbar navbar-inverse"
           style={{ margin: 0 }}>
        <ul className="nav navbar-nav">
          <li className="nav-item">
            <Link to={'/user/'}
                  className="nav-link">
              User List
            </Link>
          </li>
          <li className={classnames('nav-item', 'active', styles.activeNav)}>
            <Link to={'/group/messages'}
                  className="nav-link">
              Group Messages
            </Link>
          </li>
        </ul>
      </nav>
    );
  };

  renderMessageFilterTools = () => {
    const title = TitleByFilterType[this.getMessageFilterType()];
    return (
      <div>
        { `Showing ${title}` }
        {this.renderMessageFilterButtons()}
        {this.renderGroupMessageForm()}
      </div>
    );
  };

  renderMessageFilterButtons = () => {
    return (
      <div>
        <div className={classnames(styles.buttonContainer)}>
          { this.renderChronologicalFilterButtons() }
        </div>
        <div className={classnames(styles.buttonContainer, styles.bottomBorder)}>
          { this.renderButtonForFilterType(MessageFilterTypes.GROUP) }
        </div>
      </div>
    );
  };

  renderButtonForFilterType = (type) => {
    const title = TitleByFilterType[type];
    return (
      <button key={type}
              className={classnames('btn btn-primary', styles.filterButton )}
              onClick={this.onFilterTypeClick.bind(this, type)}>
        { title }
      </button>
    );
  };

  renderChronologicalFilterButtons = () => {
    const inboundFilterTypesOrder = List.of(MessageFilterTypes.ONE_DAY, MessageFilterTypes.TWO_DAYS, MessageFilterTypes.ONE_WEEK);
    inboundFilterTypesOrder.forEach(() => null);
    return (
      <div className="row">
        { 'Inbound:' }
        { inboundFilterTypesOrder.map(this.renderButtonForFilterType) }
      </div>
    );
  };

  renderGroupMessageForm = () => {
    const { messagesData } = this.props;
    const sentMessage = messagesData.get('messageSent');
    return (
      <form onSubmit={this.handleMessageFormSubmit.bind(this)}>
        <fieldset className="form-group">
          <input ref="groupMessageGroupId"
                 type="text"
                 className="form-control"
                 placeholder="group id" />
          <textarea ref="groupMessageBodyText"
                    type="text"
                    className="form-control"
                    placeholder="Write text message..."
                    defaultValue="" />
        </fieldset>
        <button type="submit"
                className="btn btn-primary">
          Send Message
        </button>
        { sentMessage && (
           <span className={styles.messageSent}>
             ( Message Sent )
           </span>
          )
        }
      </form>
    );
  };

  renderMessage(showApproveCancel, message, key) {
    if (!message) return null;

    const outboundSenderSetup = (message.message_type === 'outbound' ? (
      <span> : <span className={styles.sender}>{message.sender_name}</span></span>
    ) : '');

    const outboundSender = (message.message_type === 'push') ? (
      <span> : ( push notification )</span>
    ) : outboundSenderSetup;

    const bubbleStyle = (message.message_type === 'outbound' && message.sender_id ? 'coach' : message.message_type) + '-bubble';

    return (
      <div key={'message-' + key}
           className={classnames(styles.message, styles[message.message_type])}>
        <div className="row">
          <textarea ref={'msg_' + message.id}
                    className={styles[bubbleStyle]}
                    defaultValue={message.edited_body || message.body} />
        </div>

        { this.renderMessageMedia(message) }

        <div className={classnames(styles.message_info)}>
          <Link className={classnames(styles.messageNameContainer, 'nav-link')}
                to={`/user/${message.user_id}/messages`} >
            { `${message.sender_name} (${message.user_id})` }
          </Link>
          { moment(message.created_at || new Date()).format('YYYY-MM-DD hh:mm a') }
          { outboundSender }
          { showApproveCancel ? this.renderApproveCancel(message) : null}
        </div>
      </div>
    );
  }

  renderApproveCancel = (message) => {
    return (
      <div className={styles.approveCancelContainer}>
        <a onClick={this.approveMessage.bind(this, message.id)}
             role="link"
             tabIndex="0">
          APPROVE
        </a>
        :
        <a onClick={this.cancelMessage.bind(this, message.id)}
             role="link"
             tabIndex="-1">
          CANCEL
        </a>
       </div>
    );
  }

  renderMessageMedia(message) {
    /* eslint eqeqeq: 0 */
    if (!message || message.media_count == undefined || message.media_count < 1) {
      return null;
    }

    const urls = message.media.split(',');

    return (
      <div className={styles.message_info} >
        { urls.map((url) => (
          <a href={url}
             target="_blank"
             rel="noopener noreferrer">
            <img src={url}
                 role="presentation"/>
          </a>
        )) }
      </div>
    );
  }

  approveMessage = (messageId) => {
    const origValue = this.messageById(messageId).get('body');
    const inputValue = this.refs['msg_' + messageId].value;
    this.moderateMessage(messageId, 'COMPLETED', (origValue !== inputValue ? inputValue : undefined ));
  };

  cancelMessage = (messageId) => {
    this.moderateMessage(messageId, 'CANCELLED');
  };

  moderateMessage = (messageId, status, editedBody) => {
    this.props.dispatch(this.moderateGroupMessages({ messages: [ { id: messageId, message_status: status, edited_body: editedBody } ] }));
  };

  handleMessageFormSubmit(event) {
    event.preventDefault();

    const message = {
      group_id: parseInt((this.refs.groupMessageGroupId.value + '').trim(), 10),
      body: this.refs.groupMessageBodyText.value
    };

    if (isNaN(message.group_id)) {
      /* eslint no-alert: 0 */
      alert('Group ID needs to be a number');
      return;
    }

    const colonPos = message.body.indexOf(':');
    if (colonPos > -1) {
      const leadingId = parseInt(message.body.substring(0, colonPos), 10);
      if (!isNaN(leadingId)) {
        message.body = message.body.substring(colonPos + 1).trim();
        message.user_id = leadingId;
      }
    }

    if (message.body.length <= 0) {
      /* eslint no-alert: 0 */
      alert('Body text cannot be blank.');
      return;
    }

    const { dispatch } = this.props;
    dispatch(MessagesActions.updateFilterType(MessageFilterTypes.GROUP));
    dispatch(MessagesActions.sendMessageToGroup(this.props.token, message));
  }

  onFilterTypeClick = (type) => {
    const { dispatch } = this.props;
    dispatch(MessagesActions.updateFilterType(type));
    dispatch(this.fetchMessageActionForFilterType(type));
  };

  fetchMessageActionForFilterType = (_type) => {
    const { token } = this.props;
    const type = Object.values(MessageFilterTypes).includes(_type) ? _type : this.getMessageFilterType();
    if (type === MessageFilterTypes.GROUP) {
      return MessagesActions.fetchGroupMessages(token, { message_status: 'PENDING' });
    }

    const daysDiffByMessageFilterTypes = {
      [MessageFilterTypes.ONE_DAY]: 1,
      [MessageFilterTypes.TWO_DAYS]: 2,
      [MessageFilterTypes.ONE_WEEK]: 7
    };

    const fromDate = moment().subtract(daysDiffByMessageFilterTypes[type], 'days');
    const toDate = moment();
    const inboundMessageTypeValue = 1;
    return MessagesActions.fetchMessagesInDateRange(token, fromDate, toDate, inboundMessageTypeValue);
  }

  getMessageFilterType = () => {
    const { messagesData } = this.props;
    return messagesData.get('filterType') || DEFAULT_MESSAGE_FILTER_TYPE;
  };

  moderateGroupMessages(moderations) {
    return MessagesActions.moderateGroupMessages(this.props.token, moderations);
  }

  messageById = (messageId) => {
    return this.props.messages.get('messages').find((message) => messageId === message.get('id'));
  };
}
