export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const COACH_GROUP_MESSAGES = '/group/messages';
export const COACH_USER_LIST = '/user';
export const COACH_USER_DETAIL = '/user/:userId';
export const COACH_USER_MESSAGES = '/user/:userId/messages';
export const COACH_USER_NOTES = '/user/:userId/notes';
export const COACH_USER_CLICKSTREAM = '/user/:userId/clickstream';

export function withParams(route, params) {
  return Object.keys(params).reduce((r, param) => (
    r.replace(`:${param}`, params[param])
  ), route);
}

export const DEFAULT_POST_LOGIN_ROUTE = null;
