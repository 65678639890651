import { Map } from 'immutable';
import { logger } from '../lib';
import { ActionTypes } from '../constants';

const initialState = {
  loading: false,
  error: null,
  notes: null
};

export default function coachedUserNotes(state = Map(initialState), action) {
  const { payload } = action;

  switch (action.type) {
    case ActionTypes.COACH_RECEIVED_USER: {
      // making sure that the the notes are reset if the user changes
      return state.merge({ ...initialState });
    }
    case ActionTypes.COACH_REQUEST_USER_NOTES:
      return state.merge({ ...initialState, loading: true });

    case ActionTypes.COACH_RECEIVED_USER_NOTES:
      return state.merge({ ...initialState, notes: payload.notes });

    case ActionTypes.COACH_FAILED_USER_NOTES: {
      logger.error('Failed to get coached user notes', payload.error);
      return state.merge({ ...initialState, error: payload.error });
    }

    case ActionTypes.COACH_USER_NOTE_CREATED: {
      return state.merge({ ...initialState, notes: state.get('notes').push(new Map(payload.note)) });
    }

    case ActionTypes.COACH_USER_NOTE_UPDATED: {
      const notesList = state.get('notes');
      notesList.set(notesList.find( (obj) => obj.id === payload.note.id ), payload.note);
      return state.merge({ ...initialState, notes: notesList
      });
    }
    default:
      return state;
  }
}
