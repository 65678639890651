import { Map } from 'immutable';
import { logger } from '../lib';
import { ActionTypes } from '../constants';

const initialState = {
  loading: null,
  error: null,
  users: null
};

export default function coachUserList(state = Map(initialState), action) {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.COACH_REQUEST_USER_LIST:
      return state.merge({ ...initialState, loading: true });

    case ActionTypes.COACH_RECEIVED_USER_LIST:
      return state.merge({ loading: false, users: payload.coachUserList });

    case ActionTypes.COACH_FAILED_USER_LIST: {
      logger.error('Failed to get coachUserList', payload.error);
      return state.merge({ loading: false, error: payload.error });
    }

    case ActionTypes.UPDATE_COACHED_USER_IN_LIST_FOR_NEW_CLIENT:
      const { userId, clientId, clientName } = payload;
      return state.update('users', (users) => (
        users.map( (user) => (
          user.get('id') === userId ? user.merge({
            client_id: clientId,
            client_name: clientName
          }) : user ))
      ));
    default:
      return state;
  }
}
