import { Map } from 'immutable';
import logger from '../lib/logger';
import * as cookies from '../lib/cookies';
import { ActionTypes } from '../constants';

const cookieUser = cookies.user.get();
const initialState = Map({
  loading: false,
  loginError: null,
  forgotPasswordError: null,
  forgotPasswordMessage: null,
  sessionToken: cookieUser ? cookieUser.sessionToken : null,
  user: cookieUser ? cookieUser.user : null
});

export default function user(state = initialState, action = null) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.REQUEST_LOGIN:
      return state.merge({
        loading: true,
        signupError: null,
        loginError: null,
        forgotPasswordError: null,
        forgotPasswordMessage: null,
        user: null
      });
    case ActionTypes.RECEIVED_LOGIN:
      return state.merge({
        loading: false,
        signupError: null,
        loginError: null,
        forgotPasswordError: null,
        forgotPasswordMessage: null,
        sessionToken: payload.sessionToken,
        user: payload.user
      });
    case ActionTypes.FAILED_LOGIN:
      logger.error('Failed to login', payload.error);
      return state.merge({
        loading: false,
        loginError: payload.error,
        forgotPasswordError: null,
        forgotPasswordMessage: null,
        user: null
      });
    case ActionTypes.REQUEST_TEMPORARY_PASSWORD:
      return state.merge({
        loading: true,
        loginError: null,
        forgotPasswordError: null,
        forgotPasswordMessage: null,
        user: null
      });
    case ActionTypes.TEMPORARY_PASSWORD_REQUEST_SUCCESS:
      return state.merge({
        loading: false,
        loginError: null,
        forgotPasswordError: null,
        forgotPasswordMessage: payload.instruction,
        user: null
      });
    case ActionTypes.TEMPORARY_PASSWORD_REQUEST_FAIL:
      return state.merge({
        loading: false,
        loginError: null,
        forgotPasswordError: payload.error,
        forgotPasswordMessage: null,
        user: null
      });
    case ActionTypes.DID_LOGOUT:
      return state.merge({
        loading: false,
        user: null,
        sessionToken: null,
        loginError: null
      });
    default:
      return state;
  }
}
