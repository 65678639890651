import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { CoachActions } from '../../actions';
import { dataDispatch } from '../../lib/react-annotations';

import classnames from 'classnames';
import '../../styles/3ps/bootstrap.min.css';
import styles from '../../styles/coach-user.scss';


@connect( (state) => {
  const { userData } = state;
  return {
    token: userData.get('sessionToken'),
    user: userData.get('user'),
    coachedUser: state.coachedUser,
    coachedUserNotes: state.coachedUserNotes
  };
})
@dataDispatch({
  coachedUserNotes: {
    childProp: 'notes',
    loader: 'loadUserNotes'
  }
})
export default class CoachUserNotes extends Component {

  constructor(props) {
    super(props);

    this.state = {
      newNoteInput: ''
    };
  }

  // lifecycle method
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }


  render() {
    const { coachedUserNotes } = this.props;
    const coachedUser = this.props.coachedUser.get('user') ? this.props.coachedUser.get('user').toJS() : {};
    const notes = coachedUserNotes.get('notes') ? coachedUserNotes.get('notes').toJS() : [];

    return (
      <div className={classnames(styles.detailsBox, 'col-sm-10')}>
        {/* TODO: need a sexy subnav component for user pages */}
        <nav className="navbar navbar-inverse"
             style={{ margin: 0 }}>
          <ul className="nav navbar-nav">
            <li className="nav-item">
              <Link to={`/user/${coachedUser.id}`}
                    className="nav-link">
                Profile
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`/user/${coachedUser.id}/messages`}
                    className="nav-link">
                Messages
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`/user/${coachedUser.id}/clickstream`}
                    className="nav-link">
                Clickstream
              </Link>
            </li>
            <li className={classnames('nav-item', 'active', styles.activeNav)}>
              <Link to={`/user/${coachedUser.id}/notes`}
                    className="nav-link">
                Notes
              </Link>
            </li>
          </ul>
        </nav>

        <div>
          <div className={classnames('col-lg-8', styles.messagesLeft)}>
            <h3>User Notes</h3>
            {this.renderContent(notes)}
          </div>

          {
            this.shouldRenderNotes() ? this.renderUserNoteInput() : null
          }
        </div>
      </div>
    );
  }

  shouldRenderNotes = () => {
    const { coachedUserNotes } = this.props;
    return !coachedUserNotes.get('error') && !coachedUserNotes.get('loading');
  }

  renderContent = (notes) => {
    const { coachedUserNotes } = this.props;
    if (!this.shouldRenderNotes()) {
      if (coachedUserNotes.get('loading')) {
        return this.renderLoadingMessage();
      }

      return this.renderErrorMessage();
    }

    return this.renderNotes(notes);
  }

  renderErrorMessage = () => {
    const { params } = this.props;
    return (
      <div> { 'An error has occured while loading user notes for user id: ' + (params && params.userId) + ' please contact the administrator' } </div>
    );
  }

  renderLoadingMessage = () => {
    return (
      <h3>Loading User Notes...</h3>
    );
  };

  renderNotes = (notes) => {
    /* NOTES LIST */
    const scrollBoxHeight = (window.innerHeight - 230) + 'px';
    const _notes = notes || [ ];
    return (
      <div ref="message_scroll_box"
           style={{ height: scrollBoxHeight, overflowY: 'scroll' }}>
        { _notes.map(this.renderNote.bind(this)) }
      </div>
    );
  }

  renderNote(note, key) {
    return (
      <div key={'note-' + key}
           className={styles.note}>
        <div className="row">
          <div className={styles.noteBody}>
            <textarea ref={'note_' + note.id}
                      onChange={this.noteEdited.bind(this, note, false)}
                      defaultValue={note.note}
                      style={{ height: (note.note.split('\n').length * 30) + 40 }} />
          </div>
        </div>
        <div className={styles.noteInfo}>
          <span className={styles.noteSaveCancel}
                ref={'note-edit_' + note.id}>
            <a onClick={this.saveNote.bind(this, note)}
               role="link"
               tabIndex="0">
              SAVE
            </a>
            <a onClick={this.cancelNoteEdit.bind(this, note)}
               role="link"
               tabIndex="0">
              CANCEL
            </a>
          </span>
          last updated: { note.created_at }
        </div>
      </div>
    );
  }

  renderUserNoteInput = () => {
    return (
      <div className={classnames( 'col-lg-4', styles.motivesRight, styles.newNote )}>
        <form onSubmit={this.handleFormSubmit.bind(this)}>
          <textarea className="form-control"
                    placeholder="Write new note..."
                    onChange={this.handleNoteInputChange.bind(this)}
                    defaultValue={this.state.newNoteInput}
                    style={{ height: 300 }} />
          <button type="submit"
                  className="btn btn-primary">
            Create Note
          </button>
        </form>
      </div>
    );
  };

  noteEdited(note, cancelled) {
    this.refs['note-edit_' + note.id].style.display = (cancelled ? 'none' : 'block');
  }

  saveNote(note) {
    this.props.dispatch(CoachActions.updateNewUserNote(this.props.token, this.props.params.userId, note.id, this.refs['note_' + note.id].value));
    this.noteEdited(note, true);
  }

  cancelNoteEdit(note) {
    this.refs['note_' + note.id].value = note.note;
    this.noteEdited(note, true);
  }

  handleNoteInputChange(event) {
    this.setState({ newNoteInput: event.target.value });
  }

  handleFormSubmit(event) {
    event.preventDefault();
    this.props.dispatch(CoachActions.createNewUserNote(this.props.token, this.props.params.userId, { note: this.state.newNoteInput }));
    this.setState({ newNoteInput: '' });
  }

  loadUserNotes() {
    return CoachActions.fetchCoachedUserNotes(this.props.token, this.props.params.userId);
  }
}
