import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import * as HttpStatus from 'http-status-codes';
import { Link } from 'react-router';
import { NamedRoutes } from '../lib';
import { Nav, ErrorMsg } from '../components';
import { UserActions } from '../actions';

import styles from '../styles/login.scss';

@connect((state) => ({
  userData: state.userData,
  nav: state.nav
}))
export default class LoginPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: '',
      password: ''
    };

    this.onUpdateEmail = this.update.bind(this, 'email');
    this.onUpdatePassword = this.update.bind(this, 'password');
    this.onLogin = () => {
      this.props.dispatch(UserActions.login(this.state.email, this.state.password, this.props.nav));
    };
  }

  render() {
    const { userData } = this.props;
    const loading = userData.get('loading');
    const loginClasses = {
      [styles.loginButton]: true,
      [styles.loading]: loading
    };
    const error = userData.get('loginError');

    return (
      <div className={styles.container}>
        <Nav user={null} />
        <div className={styles.body}>
          <div className={styles.login}>
            <div className={styles.title}>Log in</div>
            <input type="email"
                   className={styles.input}
                   placeholder="Email"
                   value={this.state.email}
                   onChange={this.onUpdateEmail} />
            <input type="password"
                   className={styles.input}
                   placeholder="Password"
                   value={this.state.password}
                   onChange={this.onUpdatePassword} />
            <ErrorMsg error={error}
                      msgByStatus={{
                        [HttpStatus.UNAUTHORIZED]: 'Please enter a valid username and password.'
                      }} />
            <button className={classnames(loginClasses)}
                    onClick={this.onLogin}>
              Log in
            </button>
            <p className={styles.forgotPasswordContainer}>
              <Link to={NamedRoutes.FORGOT_PASSWORD}
                    className={styles.forgotPassword}>
                Forgot Password?
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }

  update(key, evt) {
    this.setState({ [key]: evt.target.value });
  }
}
