exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./utils.scss"), "");

// Module
exports.push([module.id, ".login__container___Kz9Xd {\n  width: 100%;\n  padding-top: 80px; }\n\n.login__body___3gQ7e { }\n\n.login__login___3-2EQ {\n  margin: 30px auto;\n  text-align: center;\n  max-width: 600px;\n  width: 100%;\n  padding: 40px 60px; }\n\n.login__title___WrbhP {\n  margin-bottom: 20px; }\n\n.login__input___3HSGg {\n  display: block;\n  width: 100%;\n  margin-bottom: 10px;\n  padding: 15px;\n  text-align: center; }\n\n.login__loginButton___3FHGy {\n  width: 100%;\n  margin-top: 20px; }\n\n.login__loading___3XPB- { }\n\n.login__forgotPasswordContainer___1bE3K {\n  padding-top: 20px; }\n\n.login__forgotPassword____JKtV { }\n", ""]);

// Exports
exports.locals = {
	"container": "login__container___Kz9Xd",
	"body": "login__body___3gQ7e " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["primaryContainer"] + "",
	"login": "login__login___3-2EQ " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["popup"] + "",
	"title": "login__title___WrbhP " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["popupTitle"] + "",
	"input": "login__input___3HSGg " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["inputBox"] + "",
	"loginButton": "login__loginButton___3FHGy " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["boxButton1"] + "",
	"loading": "login__loading___3XPB- " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./utils.scss").locals["loading"] + "",
	"forgotPasswordContainer": "login__forgotPasswordContainer___1bE3K",
	"forgotPassword": "login__forgotPassword____JKtV " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["secondaryTitle"] + ""
};