
import { Map } from 'immutable';
import { logger } from '../lib';
import { ActionTypes } from '../constants';

const initialCravings = {
  loading: null,
  error: null,
  cravings: null
};

export function clickstreamCravings(state = Map(initialCravings), action) {
  const { payload } = action;

  switch (action.type) {
    case ActionTypes.COACH_RECEIVED_USER: {
      // making sure that the the messages are reset if the user changes
      return state.merge({ ...initialCravings });
    }
    case ActionTypes.COACH_CRAVINGS_REQUEST:
      return state.merge({ ...initialCravings, loading: true });

    case ActionTypes.COACH_CRAVINGS_RECEIVED:
      return state.merge({ ...initialCravings, cravings: payload.cravings });

    case ActionTypes.COACH_CRAVINGS_FAILED: {
      logger.error('Failed to get user cravings', payload.error);
      return state.merge({ ...initialCravings, error: payload.error });
    }
    default:
      return state;
  }
}

const initialDaily = {
  loading: false,
  error: null,
  dailyCheckins: null
};

export function clickstreamDailyCheckins(state = Map(initialDaily), action) {
  const { payload } = action;

  switch (action.type) {
    case ActionTypes.COACH_DAILY_CHECKINS_REQUEST:
      return state.merge({ ...initialDaily, loading: true });

    case ActionTypes.COACH_DAILY_CHECKINS_RECEIVED:
      return state.merge({ ...initialDaily, dailyCheckins: payload.dailyCheckins });

    case ActionTypes.COACH_DAILY_CHECKINS_FAILED: {
      logger.error('Failed to get daily checkins', payload.error);
      return state.merge({ ...initialDaily, error: payload.error });
    }
    default:
      return state;
  }
}

const initialWeekly = {
  loading: false,
  error: null,
  weeklyCheckins: null
};

export function clickstreamWeeklyCheckins(state = Map(initialWeekly), action) {
  const { payload } = action;

  switch (action.type) {
    case ActionTypes.COACH_WEEKLY_CHECKINS_REQUEST:
      return state.merge({ ...initialWeekly, loading: true });

    case ActionTypes.COACH_WEEKLY_CHECKINS_RECEIVED:
      return state.merge({ ...initialWeekly, weeklyCheckins: payload.weeklyCheckins });

    case ActionTypes.COACH_WEEKLY_CHECKINS_FAILED: {
      logger.error('Failed to get weekly checkins', payload.error);
      return state.merge({ ...initialWeekly, error: payload.error });
    }
    default:
      return state;
  }
}

const initialSmoked = {
  loading: false,
  error: null,
  smokedEvents: null
};
export function clickstreamSmokedEvents(state = Map(initialSmoked), action) {
  const { payload } = action;

  switch (action.type) {
    case ActionTypes.COACH_JUST_SMOKED_EVENTS_REQUEST:
      return state.merge({ ...initialSmoked, loading: true });

    case ActionTypes.COACH_JUST_SMOKED_EVENTS_RECEIVED:
      return state.merge({ ...initialSmoked, smokedEvents: payload.smokedEvents });

    case ActionTypes.COACH_JUST_SMOKED_EVENTS_FAILED: {
      logger.error('Failed to get just smoked events', payload.error);
      return state.merge({ ...initialSmoked, error: payload.error });
    }
    default:
      return state;
  }
}
