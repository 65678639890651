import { ActionTypes } from '../constants';
import { Api, cookies } from '../lib';
import { handleLoginRedirect } from './nav-actions';

const { REQUEST_LOGIN, RECEIVED_LOGIN, FAILED_LOGIN, DID_LOGOUT } = ActionTypes;
const { REQUEST_TEMPORARY_PASSWORD, TEMPORARY_PASSWORD_REQUEST_SUCCESS, TEMPORARY_PASSWORD_REQUEST_FAIL } = ActionTypes;

function requestLogin(email) {
  return {
    type: REQUEST_LOGIN,
    payload: {
      email
    }
  };
}

function didLogin(token, user, nav) {
  return (dispatch) => {
    dispatch({
      type: RECEIVED_LOGIN,
      payload: {
        sessionToken: token,
        user
      }
    });
    dispatch(handleLoginRedirect(nav));
  };
}

function failedLogin(err) {
  return {
    type: FAILED_LOGIN,
    payload: {
      error: err
    }
  };
}

function didRequestTemporaryPassword() {
  return {
    type: REQUEST_TEMPORARY_PASSWORD,
    payload: { }
  };
}

function temporaryPasswordWasSent(email) {
  const instruction = 'Instructions to reset your password has been sent to ' + email;
  return {
    type: TEMPORARY_PASSWORD_REQUEST_SUCCESS,
    payload: {
      instruction
    }
  };
}

function failedRequestedTemporaryPassword(error) {
  return {
    type: TEMPORARY_PASSWORD_REQUEST_FAIL,
    payload: {
      error
    }
  };
}

export function login(email, password, nav) {
  return (dispatch) => {
    cookies.user.clear();
    dispatch(requestLogin(email));

    Api.login(email, password).then((data) => {
      const user = data.get('user');
      const token = data.get('token');
      cookies.user.set(user, token);
      dispatch(didLogin(token, user, nav));
    }).catch((err) => {
      dispatch(failedLogin(err));
    });
  };
}

export function logout() {
  cookies.user.clear();
  return {
    type: DID_LOGOUT,
    payload: {}
  };
}

export function requestTemporaryPassword(email) {
  return (dispatch) => {
    dispatch(didRequestTemporaryPassword());

    Api.requestTemporaryPassword(email).then( () => {
      dispatch(temporaryPasswordWasSent(email));
    }).catch((err) => {
      dispatch(failedRequestedTemporaryPassword(err));
    });
  };
}
